import React, { useEffect, useState } from 'react'
import Select from "react-select";
import useStyles from "./addStyles";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button } from "@material-ui/core";
import Swal from 'sweetalert2';


import data from "./data.json";
import { addNewDde } from '../../redux/actions/superAdminAction';
const AddDde = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: addNewDdeData } = useSelector((state) => state.addNewDde);
  const [edit, setEdit] = useState(false);
  const [district, setDistrict] = useState(null);
  const[dde, setDde] = useState("");
  const handleCheck = () => {
    setEdit(!edit);
  }

  const handleDistrict = (e) => {
    setDistrict(e);
  };
  const handleSubmit = (e) => {
    if(dde || district !== null){
      dispatch(addNewDde(district.district.toLowerCase() , dde.toUpperCase()))
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'error',
        text: "Please select a district and provide a DDE name ",
      })
    }
  }

  useEffect(() => {
    if (addNewDdeData) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: "DDE added successfully",
      })
    }
  }, [addNewDdeData])

  return (
    <div className={classes.container}>
      <div className={classes.paper}>
        <Typography variant="h4" className={classes.text}>
          ADD DDE
        </Typography>
        <Select
          className={classes.dropdown}
          placeholder="Select District"
          value={district}
          options={data}
          onChange={(e) => handleDistrict(e)}
          getOptionLabel={(x) => x.district}
          getOptionValue={(x) => x.district}
        />

        <div className={classes.group}>
          <label className={classes.label}>DDE:</label>

          <input
            className={classes.input}
            value={dde}
            onChange={(e)=> { setDde(e.target.value)}}

          />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}

          >
            ADD
          </Button>


        </div>

      </div>
      )
}

      export default AddDde