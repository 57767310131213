import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { List, ListItem } from "@material-ui/core";
import Box from "@mui/material/Box";
import logo from '../../images/logo.svg';
//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import useStyles from "./styles";
import { Button } from "@mui/material";
import { ArrowDropDown } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux"
import { logoutUser } from "../../redux/actions/userAction";

const Navbar = () => {

    const { userInfo } = useSelector(state => state.userSignin);

    const dispatch = useDispatch();

    //react useState hook to save the current open/close state of the drawer, normally variables dissapear afte the function was executed
    const [open, setState] = useState(false);
    const classes = useStyles();
    const listItem = [
        {
            text: 'Resources',
            onclick: '/#'
        },
        {
            text: 'Courses',
            onclick: '/courses',
        },
        {
            text: 'News',
            onclick: '/news',
        },
        {
            text: 'Classes',
            onclick: '/matricula-classes',
        },

    ];
    //function that is being called every time the drawer should open or close, the keys tab and shift are excluded so the user can focus between the elements with the keys
    const toggleDrawer = (open) => () => {

        //changes the function state according to the value of open
        setState(open);

    };


    return (
        <div className={classes.navbar}>
            <Link to="/">
                <img className={classes.logo} to="/" src={logo} alt="icon" height="100px" />
            </Link>
            {/* web view */}
            <List className={classes.list}>
                {listItem.map((item, index) => {
                    const { text, onclick } = item;
                    return (
                        <a target="_blank" rel="noreferrer" key={text} href={"https://www.matricula.co.in" + onclick} className={classes.textBlock} >
                            <ListItem >
                                <ListItemText disableTypography className={classes.text} primary={text} />
                            </ListItem>
                        </a>
                    )
                })}
                <div className={classes.textBlock}>
                    <a rel="noreferrer" href="/#" className={classes.text} style={{ textDecoration: "none", color: "black", padding: "0rem 1.6rem" }}>TEST-SERIES
                    </a>
                </div>
                <div className={classes.textBlock}>
                    <a target="_blank" rel="noreferrer" href="https://mantra.matricula.co.in/" className={classes.text} style={{ textDecoration: "none", color: "black", padding: "0rem 1.6rem" }}>Mantra
                    </a>
                </div>


            </List>



            {/* mobile view */}
            {/* hamburger icon to toggle */}
            <IconButton sx={{
                display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "none"
                }, marginLeft: "auto"
            }} className={classes.iconButton} color="inherit" onClick={toggleDrawer(true)}>
                <MenuIcon sx={{ fontSize: "3rem" }} />
            </IconButton>



            <Drawer
                variant='temporary'
                anchor="top"
                //if open is true --> drawer is shown
                open={open}
                //function that is called when the drawer should close
                onClose={toggleDrawer(false)}
                //function that is called when the drawer should open
                onOpen={toggleDrawer(true)}
                className={classes.drawer}


            >
                {/* The inside of the drawer */}

                <Box >
                    {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
                    <IconButton  >
                        <CloseIcon onClick={toggleDrawer(false)} sx={{ padding: "1rem", fontSize: "2.5rem" }} />
                    </IconButton>

                    <Divider />
                    <List className={classes.box} sx={{}}>
                        {listItem.map((item, index) => {
                            const { text, onclick } = item;
                            return (
                                <a target="_blank" rel="noreferrer" key={text} href={"https://www.matricula.co.in" + onclick} className={classes.textBlock} >
                                    <ListItem >
                                        <ListItemText disableTypography className={classes.text} primary={text} />
                                    </ListItem>
                                </a>
                            )
                        })}

                        <div className={classes.textBlock} style={{ padding: "2rem 0rem"}}>
                            <a rel="noreferrer" href="/#" className={classes.text} style={{ textDecoration: "none", color: "black", padding: "2rem 0rem" }}>TEST-SERIES
                            </a>
                        </div>
                        <div className={classes.textBlock} style={{ padding: "2rem 0rem"}}>
                            <a target="_blank" rel="noreferrer" href="https://mantra.matricula.co.in/" className={classes.text} style={{ textDecoration: "none", color: "black",  }}>Mantra
                            </a>
                        </div>

                        <div><Button className={classes.button} href="https://community.matricula.co.in/" target="_blank" variant="contained" sx={{
                            fontSize: { xs: "1.2rem", sm: "1.6rem" }, display: {
                                xs: "block",
                                sm: "none",
                                md: "none",
                            }, padding: "1.5rem", borderRadius: "1rem", marginTop: "2rem"
                        }} >JOIN THE COMMUNITY</Button></div>

                        {
                            userInfo ?
                                <div style={{ margin: "2rem 7rem 2rem 0rem" }}><Button
                                    onClick={() => { dispatch(logoutUser()) }}
                                    className={classes.button} variant="contained" sx={{
                                        fontSize: { xs: "1.2rem", sm: "1.6rem" }, display: {
                                            xs: "block",
                                            sm: "none",
                                            md: "none",
                                        }, padding: "1.5rem", borderRadius: "1rem", margin: "2rem 3.8rem", backgroundColor: "red", textAlign: "center"
                                    }}  >LOGOUT</Button></div>
                                : ""
                        }


                    </List>


                </Box>
            </Drawer>


            <div style={{ margin: "2rem 2rem 2rem 0rem" }}><Button className={classes.button} href="https://community.matricula.co.in/" target="_blank" variant="contained" sx={{
                fontSize: "1.5rem", padding: "1rem", borderRadius: "1rem", display: {
                    xs: "none",
                    sm: "block",
                    md: "block"
                },
            }} >JOIN THE COMMUNITY</Button></div>

            {userInfo ?
                <div style={{ margin: "0rem 5rem 0rem 0rem" }}><Button
                    onClick={() => { dispatch(logoutUser()) }}
                    className={classes.button} variant="contained" sx={{
                        fontSize: "1.5rem", padding: "1rem", borderRadius: "1rem", backgroundColor: "red", display: {
                            xs: "none",
                            sm: "block",
                            md: "block"
                        },
                    }} >LOGOUT</Button></div>
                : ""}




        </div>


    );
}
export default Navbar;