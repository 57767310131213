import { Button } from '@mui/material';
import React, { useEffect } from 'react'
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { getRegisterStudentDetails } from '../../redux/actions/superAdminAction';
import useStyles from './RegistererStudentDetails.style'

export default function RegistererStudentDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getRegisterStudentDetails);

  useEffect(() => {
    dispatch(getRegisterStudentDetails());
  }, [dispatch]);


  const dataToExport = [];

  data &&
    data.data.map((currentElement) => {
      const oneRwo = {
        Registration_No: currentElement.registrationNo,
        District: currentElement.district,
        BLock: currentElement.block,
        DDM: currentElement.ddm,
        DDE: currentElement.dde,
        School: currentElement.schoolName,
        School_Type: currentElement.schoolType,
        Class: currentElement.studentClass,
        Board: currentElement.board,
        Principal_Contact: currentElement.principalContact,
        Name: `${currentElement.firstName} ${currentElement.lastName}`,
        Contact: currentElement.phoneNumber,
        Alternative_No: currentElement.AlternativeNo,
        Email: currentElement.email,
        Subject1: currentElement.registerSubject1,
        Subject2: currentElement.registerSubject2,
        Subject3: currentElement.registerSubject3,
        Subject4: currentElement.registerSubject4,
        Subject5: currentElement.registerSubject5,
        Subject6: currentElement.registerSubject6,
        Subject7: currentElement.registerSubject7,
        Amount:currentElement.amountTakenForRegistration,
      };
      dataToExport.push(oneRwo);
    });

  return (
    <>
            <div className={classes.container}>
            <div className={classes.header}>
        <CSVLink  filename="REGISTERED_STUDENT_DETAILS" data={dataToExport} target="_blank" style={{textDecoration:'none'}}>
          <Button variant="contained" style={{textDecoration:'none'}}>Download Student Details</Button>
        </CSVLink>
      </div>
      <table className={classes.table}>
        <tr className={classes.tableRow}>
          <th className={classes.tableElement}>Registration No</th>
          <th className={classes.tableElement}>District</th>
          <th className={classes.tableElement}>BLock</th>
          <th className={classes.tableElement}>DDM</th>
          <th className={classes.tableElement}>DDE</th>
          <th className={classes.tableElement}>School</th>
          <th className={classes.tableElement}>School Type</th>
          <th className={classes.tableElement}>Class</th>
          <th className={classes.tableElement}>Board</th>
          <th className={classes.tableElement}>Principal Contact</th>
          <th className={classes.tableElement}>Name</th>
          <th className={classes.tableElement}>Contact</th>
          <th className={classes.tableElement}>Alternative No</th>
          <th className={classes.tableElement}>Email</th>
          <th className={classes.tableElement}>Subject 1</th>
          <th className={classes.tableElement}>Subject 2</th>
          <th className={classes.tableElement}>Subject 3</th>
          <th className={classes.tableElement}>Subject 4</th>
          <th className={classes.tableElement}>Subject 5</th>
          <th className={classes.tableElement}>Subject 6</th>
          <th className={classes.tableElement}>Subject 7</th>
          <th className={classes.tableElement}>Amount</th>
        </tr>



        {data &&
          data.data.map((currentData, index) => {
            return (
              <tr key={index}>
                <td className={classes.tableElement}>
                    {currentData.registrationNo}
                </td>
                <td className={classes.tableElement}>
                {currentData.district}
                </td>
                <td className={classes.tableElement}>
                {currentData.block}
                </td>
                <td className={classes.tableElement}>
                {currentData.ddm}

                </td>
                <td className={classes.tableElement}>
                {currentData.dde}
                </td>
                <td className={classes.tableElement}>
                {currentData.schoolName}
                </td>
                <td className={classes.tableElement}>
                {currentData.schoolType}
                </td>
                <td className={classes.tableElement}>
                {currentData.studentClass}
                </td>
                <td className={classes.tableElement}>
                {currentData.board}
                </td>
                <td className={classes.tableElement}>
                {currentData.principalContact}
                </td>
                <td className={classes.tableElement}>
                    {currentData.firstName} {currentData.lastName}
                </td>
                <td className={classes.tableElement}>
                {currentData.phoneNumber}
                </td>
                <td className={classes.tableElement}>
                {currentData.AlternativeNo}
                </td>
                <td className={classes.tableElement}>
                {currentData.email}
                </td>
                <td className={classes.tableElement}>
                   {currentData.registerSubject1}
                </td>
                <td className={classes.tableElement}>
                   {currentData.registerSubject2}
                </td>
                <td className={classes.tableElement}>
                   {currentData.registerSubject3}
                </td>
                <td className={classes.tableElement}>
                   {currentData.registerSubject4}
                </td>
                <td className={classes.tableElement}>
                   {currentData.registerSubject5}
                </td>
                <td className={classes.tableElement}>
                   {currentData.registerSubject6}
                </td>
                <td className={classes.tableElement}>
                   {currentData.registerSubject7}
                </td>
                <td className={classes.tableElement}>
                {currentData.amountTakenForRegistration}
                </td>
              </tr>
            );
          })}
      </table>
    </div>
    </>
  )
}
