import React from 'react'
import { Paper, Typography, Container, Button } from "@material-ui/core";
import {useNavigate} from 'react-router-dom'
// import { DownloadIcon, DropdownIcon } from '@material-ui/icons';
import useStyles from './styles';
import {useSelector} from "react-redux"
import Result from '../Result/Result';
// import AdmitCard from '../admitCard/admitCard'


const Dash = () => {
    const { userInfo } = useSelector((state) => state.userSignin)

    const urlPrefix = `https://quordnetacademy.fra1.digitaloceanspaces.com/MATRICULA-MOCK-TEST-ADMIT-CARD-2022/${userInfo?.data?.CheckStudent?.phoneNumber}.pdf`;

    const navigate = useNavigate();

    const classes = useStyles();
    return (
        <Container className={classes.container}>
            {/* <Paper elevate={6} className={classes.paper}>
                <Typography variant="h4" className={classes.text}>Welcome {userInfo && userInfo.data.CheckStudent.firstName}</Typography>
                <Typography variant="h5" className={classes.text}>Download your Admit card: </Typography>
                <Button color="primary" variant="contained" onClick={()=> navigate('/admit-card')} className={classes.submit}>View Admit Card </Button>
                <AdmitCard />
            </Paper> */}
            <Paper className={classes.paper2}>
                <Typography variant="h6" className={classes.text}>Click the Button to Download your MATRICULA MOCK TEST 2022 Admit Card</Typography>
                <a href={urlPrefix} target="_blank" rel="noopener noreferrer" style={{
                    textDecoration: 'none',
                    width: "100%",
                    fontSize: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    
                }}>
                    <Button color="primary" variant="contained" className={classes.submit}>Click Here</Button>
                </a>
            </Paper>
            <div 
            className={classes.admitViewer}
                >
                    <Result/>
                    {/* <iframe
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none'
                    }}
                    src={urlPrefix} frameborder="0"></iframe> */}
                </div>

        </Container>
    )
};

export default Dash