import React, { useEffect, useState } from 'react'
import { Paper, Button, Typography } from '@mui/material';
import useStyles from './styles'
import Select from 'react-select';
import Swal from 'sweetalert2';
import data from "./data.json";
import { useDispatch, useSelector } from "react-redux";
import { addVenue, getAllConvertedSchool } from '../../redux/actions/superAdminAction';
import { getBlocks,getSchools } from '../../redux/actions/adminAction';


const EnterVenue = () => {

  const [schoolName, setSchoolName] = useState();
  const [venue, setVenue] = useState();
  const [venueCapacity, setVenueCapacity] = useState();
  
  const [district, setDistrict] = useState(null);
  const [block, setBlock] = useState(null);
  const [edit, setEdit] = useState(false);
  const [checked, setChecked] = useState(false);


    
    const classes = useStyles();
    const dispatch = useDispatch();
    // const { data: convertedSchools } = useSelector((state) => state.getAllConvertedSchool);
    // const { data: convertedSchoolDistrict } = useSelector((state) => state.getDistrictForConvertedSchool);
    const { data: addVenueData, error } = useSelector((state) => state.addVenue);
    const { data: blockData } = useSelector((state) => state.getBlocks);
    const { data: schoolData } = useSelector((state) => state.getSchools);



    const handleDistrict = (e) => {
      dispatch(getBlocks(e.district.toLowerCase()));
      setDistrict(e);
      // setBlockList(e.block);
      setBlock(null);
      // setDdm(e.ddm);
    };

    const handleBlock = (e) => {
      setBlock(e);
      dispatch(getSchools(district.district.toLowerCase(), e.block));
    };


    const handleCheck = () => {
      setChecked(!checked)
      setEdit(!edit);
    }


    useEffect(() => {
      dispatch(getAllConvertedSchool())
    }, [dispatch])

    useEffect(() => {
      if(addVenueData){
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: `Venue added for ${schoolName.school}, Thank You`,
        })
        setVenue("")
        setVenueCapacity("")
      }
    }, [addVenueData,schoolName])

    useEffect(() => {
      if (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
        })
      }
    }, [error])

    // const handleSchool = (e) =>{
    //   setSchoolName(e)
    //   dispatch(getDistrictForConvertedSchool(e.school))
    // }
    
    const handleSubmit = () =>{
      dispatch(addVenue(schoolName.school,venue,venueCapacity))
    }
  return (
    <div className={classes.container}>
            
    <Paper className={classes.paper} sx={{boxShadow: '0 0 .3rem .2rem #185694'}}>
    <Typography variant="h3" className={classes.text}>
        Enter Exam Venue
    </Typography>


    <>

<Select
  className={classes.dropdown}
  placeholder="Select District"
  value={district}
  options={data}
  onChange={(e) => handleDistrict(e)}
  getOptionLabel={(x) => x.district}
  getOptionValue={(x) => x.district}
/>

<Select
  className={classes.dropdown}
  placeholder="Select Block"
  value={block}
  options={blockData && blockData.data.Blocks}
  onChange={(e) => handleBlock(e)}
  getOptionLabel={(x) => x.block}
  getOptionValue={(x) => x.block}
/>



{edit ?
  <input onChange={(e) => setSchoolName({...schoolName,school:e.target.value.toLocaleUpperCase()})} value={schoolName && schoolName.school} placeholder="Enter School Name" className={classes.editInput} />

  :
  <Select
    className={classes.dropdown}
    placeholder="Select School Name"
    value={schoolName}
    options={schoolData && schoolData.data.schools}
    onChange={(e) => setSchoolName(e)}
    getOptionLabel={(x) => x.school}
    getOptionValue={(x) => x.school}
  />
}

<div style={{ fontSize: "1.5rem", }}>
  <label htmlFor="check" style={{ color: "grey" }}>Other School ? </label>

  <input type="checkbox" defaultChecked={checked} onChange={handleCheck} name="check" style={{ marginRight: ".5rem" }} />
</div>  


        <input className={classes.item} placeholder="Enter Venue" value={venue} onChange={(e)=>{setVenue(e.target.value)}} />
        <input className={classes.item} placeholder="Enter Venue Capacity" value={venueCapacity} onChange={(e)=>{setVenueCapacity(e.target.value)}} />
        <Button onClick={handleSubmit} type="submit" variant='contained' sx={{fontSize: "1.5rem",padding: "1rem",background: "#185694"}} color="primary" className={classes.submit}>Submit</Button>
  </>

    </Paper>
   

</div>
  )
}

export default EnterVenue