import {signInUser} from "../../redux/actions/userAction"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import Swal from 'sweetalert2';
import { useEffect } from "react";


export const LoginLogic = () =>{

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [uniqueId, setUniqueId] = useState("")
    const { userInfo,error } = useSelector((state) => state.userSignin)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginHandler = (e) => {
        e.preventDefault();
        if(uniqueId && firstName && lastName){
            dispatch(signInUser(uniqueId,firstName,lastName));
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all the fields',
              })
        }
    };

    useEffect(() => {
        if(userInfo){
            navigate("/student/dashboard")
        }
    }, [userInfo,navigate])

    useEffect(() => {
        if(error){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
              })
        }
    }, [error])
    


    return {
        userInfo,
        error,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        uniqueId,
        setUniqueId,
        loginHandler
    }
}