import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/auth/auth";
import Dashboard from "./components/dashboard/dash";
import NavBar from "./components/navBar/navbar";
import SuperAdmin from "./components/superAdmin/superAdmin";
import SuperAdminHome from "./components/superAdminHome/superAdminHome";
// import Admin from "./components/admin/admin";
// import AdminLogin from "./components/admin/adminLogin";
import SuperAdminVenue from "./components/superAdminHome/EnterVenue";
import SuperAdminDate from "./components/superAdminHome/EnterDate";
import Footer from "./components/Footer/Footer";
import AdmitCard from "./components/admitCard/admitCard";
import TrackBdms from "./components/TrackBdms";
import TrackSingleDdmDde from "./components/TrackSingleDdm-Dde";
import SuperAdminBdm from './components/superAdminHome/BdmDetails'
import DdeForm from './components/ddeForm/ddeForm'
import AddDde from './components/superAdminHome/AddDde'
import UpdateDde from './components/superAdminHome/updateDde'
import AddDdm from './components/superAdminHome/AddDdm'
import RegistererStudentDetails from "./components/RegistererStudentDetails";
import MantraStudentsDetails from "./components/MantraStudentsDetails";
import {
  UserProtection,
  // AdminProtection,
  SuperAdminProtection,
} from "./Layout/RouteProtection";
import AddMarks from "./components/superAdminHome/AddMarks";
const App = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route exact path="" element={<Login />} />
        <Route
          exact
          path="/student/dashboard"
          element={
            <UserProtection>
              <Dashboard />
            </UserProtection>
          }
        />
        <Route exact path="/admit-card" element={
          <UserProtection>
            <AdmitCard />
          </UserProtection>
        } />
        {/* <Route
          exact
          path="/admin/dashboard"
          element={
            <AdminProtection>
              <Admin />
            </AdminProtection>
          }
        />
        <Route exact path="/admin-login" element={<AdminLogin />} /> */}
        <Route exact path="/super-admin-login" element={<SuperAdmin />} />

        <Route
          exact
          path="/super-admin-home"
          element={
            <SuperAdminProtection>
              <SuperAdminHome />
            </SuperAdminProtection>
          }
        />
        <Route
          exact
          path="/add-student-marks"
          element={
            <SuperAdminProtection>
              <AddMarks />
            </SuperAdminProtection>
          }
        />
        <Route
          exact
          path="/super-admin-venue"
          element={
            <SuperAdminProtection>
              <SuperAdminVenue />
            </SuperAdminProtection>
          }
        />
        <Route
          exact
          path="/super-admin-date"
          element={
            <SuperAdminProtection>
              <SuperAdminDate />
            </SuperAdminProtection>
          }
        />
        <Route
          exact
          path="/super-admin-ddm"
          element={
            <SuperAdminProtection>
              <AddDdm />
             </SuperAdminProtection>
          }
        />
        <Route
          exact
          path="/super-admin-dde-edit"
          element={
            <SuperAdminProtection>
              <UpdateDde />
            </SuperAdminProtection>
          }
        />
        <Route
          exact
          path="/super-admin-dde"
          element={
            <SuperAdminProtection>
              <AddDde />
            </SuperAdminProtection>
          }
        />
        <Route
          exact
          path="/registered-student-details"
          element={
            <SuperAdminProtection>
              <RegistererStudentDetails />
            </SuperAdminProtection>
          }
        />
        <Route
          exact
          path="/mantra-student-details"
          element={
            <SuperAdminProtection>
              <MantraStudentsDetails />
            </SuperAdminProtection>
          }
        />
        <Route exact path="/student-registration" element={<DdeForm />} />
        <Route exact path="/super-admin-bdm" element={<SuperAdminBdm />} />
        <Route exact path="/track-bdms" element={<TrackBdms />} />
        <Route exact path="/track-single-ddm-dde" element={<TrackSingleDdmDde />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
