import axios from 'axios'
import {
    USER_SIGNIN_REQUEST,
    USER_SIGNIN_SUCCESS,
    USER_SIGNIN_FAILED,
    USER_LOGOUT,
  } from '../constants/userConstants'





  const localStorage = window.localStorage
const API =  process.env.REACT_APP_NODE_API

console.log('====================================');
console.log(API);
console.log('====================================');


  // signIn user
  export const signInUser = (uniqueID,firstName,lastName) => async (dispatch) => {
    try {
      dispatch({
        type: USER_SIGNIN_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const body = {
        uniqueID,
        firstName,
        lastName,
      }
      const { data } = await axios.post(`${API}/public/student/auth/login`, body, config)
      dispatch({
        type: USER_SIGNIN_SUCCESS,
        payload: data
      })
      localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: USER_SIGNIN_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }


  // user logout
export const logoutUser = () => (dispatch) => {
    if (localStorage.getItem('userInfo')) {
      localStorage.removeItem('userInfo')
    }
    dispatch({
      type: USER_LOGOUT
    })
  }