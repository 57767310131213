import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom'


export const UserProtection = ({ children }) => {
    const { userInfo } = useSelector((state) => state.userSignin)

    if (!userInfo) {
        return <Navigate to="/"/>;
      }
    
      return children;
}

export const AdminProtection = ({ children }) => {
    const { adminInfo } = useSelector((state) => state.adminSignin)

    if (!adminInfo) {
        return <Navigate to="/admin-login"/>;
      }
    
      return children;
}
export const SuperAdminProtection = ({ children }) => {
    const { superAdminInfo } = useSelector((state) => state.superAdminSignin)

    if (!superAdminInfo) {
        return <Navigate to="/super-admin-login"/>;
      }
    
      return children;
}