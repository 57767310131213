import React from 'react'

export const ResultLogic = () => {
    
    //Number To Words
    function NumberToWords (num) {
    let ones = ['','One','Two','Three','Four','Five','Six','Seven','Eight','Nine','Ten','Eleven','Twelve','Thirteen','Fourteen','Fifteen','Sixteen','Seventeen','Eighteen','Nineteen'];
    let tens = ['','','Twenty','Thirty','Forty','Fifty','Sixty','Seventy','Eighty','Ninety'];
    let scales = ['','Thousand','Million','Billion','Trillion'];
    let words = '';
    if (num === 0) return 'Zero';
    for (let i = 0; i < scales.length; i++) {
        let tempNumber = num % (100 * Math.pow(1000, i));
        if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
            if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
                words = ones[Math.floor(tempNumber / Math.pow(1000, i))] + " " + scales[i] + " " + words;
            } else {
                words = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + "-" + ones[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + " " + scales[i] + " " + words;
            }
        }
        tempNumber = num % Math.pow(1000, i + 1);
        if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) words = ones[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + " Hundred " + words;
    }
    words = words.trim()
    if(words.charAt(words.length-1) === '-'){
        words = words.slice(0,words.length-1);
    }
    return words;
    }

    
const gradingData = ['Grade Scale','Grade','Performance Indicator']
const gradingRowData = [
    {
        gradeScale: '90-100',
        grade: 'AA',
        performanceIndicator: 'Outstanding'
    },
    {
        gradeScale: '80-89',
        grade: 'A+',
        performanceIndicator: 'Excellent'
    },
    {
        gradeScale: '60-79',
        grade: 'A',
        performanceIndicator: 'Very Good'
    },
    {
        gradeScale: '45-59',
        grade: 'B+',
        performanceIndicator: 'Good'
    },
    {
        gradeScale: '35-44',
        grade: 'B',
        performanceIndicator: 'Satisfactory'
    },
    {
        gradeScale: '25-34',
        grade: 'C',
        performanceIndicator: 'Marginal'
    },
    {
        gradeScale: 'Below 25',
        grade: 'D',
        performanceIndicator: 'Disqualified'
    }
]

const calculateGrade = (marksObtained) => {
    if(marksObtained >= 90) return 'AA'
    else if(marksObtained >= 80) return 'A+'
    else if(marksObtained >= 60) return 'A'
    else if(marksObtained >= 45) return 'B+'
    else if(marksObtained >= 35) return 'B'
    else if(marksObtained >= 25) return 'C'
    else return 'D'
}

const resultHeaders = ['Compulsory Subject','Full Marks','Pass Marks','Marks Obtained','Grade']



   return {
        NumberToWords, gradingData, gradingRowData, calculateGrade, resultHeaders
   }

}

export default ResultLogic



