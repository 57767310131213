import React, { useEffect } from "react";

import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getDDMActivity } from "../../redux/actions/superAdminAction";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";

const BdmDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getDDMActivity);
  useEffect(() => {
    dispatch(getDDMActivity());
  }, [dispatch]);

  const dataToExport = [];

  data &&
    data.data.map((currentElement) => {
      const oneRwo = {
        Name: currentElement.name,
        Info_Date: new Date(currentElement.firstLoginTime).toLocaleDateString(),
        First_Login_Time: new Date(
          currentElement.firstLoginTime
        ).toLocaleTimeString(),
        Last_Logout_Time: new Date(
          currentElement.lastLogoutTime
        ).toLocaleTimeString(),
      };
      dataToExport.push(oneRwo);
    });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <CSVLink  filename={`DDM_DDE_LOGS_${new Date().toLocaleDateString()}`} data={dataToExport} target="_blank" style={{textDecoration:'none'}}>
          <Button variant="contained" style={{textDecoration:'none'}}>Download Today's Logs</Button>
        </CSVLink>
      </div>
      <table className={classes.table}>
        <tr className={classes.tableRow}>
          <th className={classes.tableElement}>NAME</th>
          <th className={classes.tableElement}>INFO DATE</th>
          <th className={classes.tableElement}>FIRST LOGIN TIME</th>
          <th className={classes.tableElement}>LAST LOGOUT TIME</th>
        </tr>
        {data &&
          data.data.map((currentData, index) => {
            return (
              <tr key={index}>
                <td className={classes.tableElement}>{currentData.name}</td>
                <td className={classes.tableElement}>
                  {new Date(currentData.firstLoginTime).toLocaleDateString()}
                </td>
                <td className={classes.tableElement}>
                  {new Date(currentData.firstLoginTime).toLocaleTimeString()}
                </td>
                <td className={classes.tableElement}>
                  {new Date(currentData.lastLogoutTime).toLocaleString()}
                </td>
              </tr>
            );
          })}
      </table>
    </div>
  );
};

export default BdmDetails;
