import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({


    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "30%",
        // height: "80%",
        // height: "40%",
        margin: "auto",
        textAlign: "center",
        padding: "1rem",
        boxShadow: '0 0 .3rem .2rem #185694',

        [theme.breakpoints.down('sm')]: {
            margin: "auto",
            width: "70%",
        },

    },
    paper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "90%",
        height: "100%",
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: "80%",
            // marginBottom: "5rem",
        },
    },


    group: {
        width: "100%",
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    input: {
        width: "100%",
        margin: "1rem",
        padding: "1.5rem",
        borderRadius: "4rem",
        outline: "none",
        border: "2px solid black",
        fontWeight:"bolder",
        textAlign:'center'
    },
    label: {
        // width: "40%",
        // paddingRight: "1rem",
        fontSize: "2rem",
        [theme.breakpoints.down('sm')]: {
            width: "10rem",
            textAlign: "center",
            fontSize: "1.5rem"
        },

    },
    dropdown: {
        width: "75%",
        cursor: "pointer",
        fontSize: "1.6rem",
        outline: "none",
        padding: "1rem",
        margin: "auto",
        textAlign: "center",
        background: "transparent",

        [theme.breakpoints.down('sm')]: {
            width: "100%",

        },
    },

    submit: {
        width: "40%",
        margin: "1rem",
        padding: "1rem",
        borderRadius: "1.2rem",
        backgroundColor: "#185694",
        fontSize: "1.4rem"
    },
  

}));