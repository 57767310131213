import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import useStyles from "./TrackSingleDdm-Dde.style";
import { MapContainer, TileLayer } from "react-leaflet";
import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";
import { useDispatch, useSelector } from "react-redux";
import { getDDMActivity } from "../../redux/actions/superAdminAction";
// eslint-disable-next-line
// import "leaflet/dist/leaflet.css";

const API = process.env.REACT_APP_BDM_TRACKING_API;

export default function TrackBdms() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState(null);
  const [RouteComponent, setRouteComponent] = useState(null);
  const [showCOntrolPanel, setShowCOntrolPanel] = useState(true);
  const [date, setDate] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: userIncomingName } = useSelector(
    (state) => state.getDDMActivity
  );

  useEffect(() => {
    dispatch(getDDMActivity(true));
  }, []);

  const handleUser = (e) => {
    setUser(e._id);
    setUserName(e);
  };

  const getSIngleUserTrack = (e) => {
    const lastNo = date.split("-")[2];
    const increasedLastNo =
      `${parseInt(lastNo)}`.length === 1
        ? `0${parseInt(lastNo) + 1}`
        : `${parseInt(lastNo) + 1}`;
    const endDate =
      date.split("-")[0] + "-" + date.split("-")[1] + "-" + increasedLastNo;
    getData(user, date, endDate);
  };

  const [bdmAllDetails, setBdmAllDetails] = useState([]);

  async function getData(user, startDate, endDate) {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      user,
      startDate,
      endDate,
    };
    const { data } = await axios.post(
      `${API}/private/user-management/track-single-user-route`,
      body,
      config
    );
    console.log('====================================');
    console.log(data);
    console.log('====================================');
    const { UserRoute } = data;
    setBdmAllDetails(UserRoute);

    const arr = [];

    const latLong =
      UserRoute &&
      UserRoute.map((item) => {
        arr.push([parseFloat(item.latitude), parseFloat(item.longitude)]);
      });

    const createRoutineMachineLayer = (props) => {
      const instance = L.Routing.control({
        waypoints: arr,
        lineOptions: {
          styles: [{ color: "red", weight: 4 }],
        },
        show: true,
        addWaypoints: false,
        routeWhileDragging: true,
        draggableWaypoints: true,
        fitSelectedRoutes: true,
        showAlternatives: false,
        createMarker: function () {
          return null;
        },
      });

      return instance;
    };
    setLoading(false);
    setRouteComponent(createControlComponent(createRoutineMachineLayer));
  }

  return (
    <>
      <div className={classes.toggleControlPanel}>
        <button
          onClick={() => setShowCOntrolPanel(!showCOntrolPanel)}
          className={classes.toggleBtn}
        >
          {showCOntrolPanel ? "Hide" : "Show"} Control panel
        </button>
      </div>

      {showCOntrolPanel ? (
        <div
          className={classes.controlPanel}
          style={
            {
              // display: "none",
            }
          }
        >
          <Select
            className={classes.dropdown}
            placeholder="Select User"
            value={userName}
            options={userIncomingName && userIncomingName.BdmDetails}
            onChange={(e) => handleUser(e)}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.name}
          />
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className={classes.item}
            name="date"
          />
          
          <button className={classes.trackBtn} disabled={loading} onClick={getSIngleUserTrack}>
            {loading ? 'Loading...' : 'Track'}
          </button>
        </div>
      ) : (
        ""
      )}

      <MapContainer
        doubleClickZoom={false}
        id="mapId"
        zoom={14}
        center={[22.497187, 88.3241283]}
      >
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
          attribution="Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri"
        />
        {bdmAllDetails.length > 0 ? <RouteComponent /> : ""}
      </MapContainer>
    </>
  );
}
