import React from 'react'
import { Paper, Button, Typography } from '@mui/material';
import useStyles from './styles';

const EnterDate = () => {
    
    const classes = useStyles();
  return (
    <div className={classes.container}>
    <Paper className={classes.paper} sx={{boxShadow: '0 0 .3rem .2rem #185694'}}>
    <Typography variant="h3" className={classes.text}>
        Enter Exam Date
    </Typography>
        <select className={classes.itemSelect}>
            <option value="" selected="selected"  className={classes.select} >Select Subject</option>
            <option value=""   className={classes.select} >Physics</option>
            <option value=""  className={classes.select} >Chemistry</option>
            <option value="" className={classes.select}  >Mathematics</option>
            <option value=""  className={classes.select} >Biology</option>
        </select>
        <input type="date" className={classes.item} name="date" />
        <label className={classes.label}>Start Time: </label>
        <input type="time"  className={classes.item} name="start-time" />
        <label className={classes.label}>End Time: </label>
        <input type="time"className={classes.item} id="appt" name="end-time" />
        <input type="number" className={classes.item} placeholder="Enter Number of Students" />
        <Button type="submit" variant='contained' sx={{fontSize: "1.5rem",padding: "1rem",background: "#185694"}} color="primary" className={classes.submit}>Submit</Button>

    </Paper>
    </div>
  )
}

export default EnterDate