import { Button } from '@mui/material';
import React, { useEffect } from 'react'
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './RegistererStudentDetails.style'

export default function RegistererStudentDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);


  const getAllRegisterStudentDetails = async () => {
    const response = await fetch('https://api.mantra.matricula.co.in/private/student/activity/get-all-students-details');
    const data = await response.json();
    setData(data);
  }



  useEffect(() => {
    getAllRegisterStudentDetails();
  }, [dispatch]);


  const dataToExport = [];
  data.data &&
    data.data.map((currentElement) => {
      const oneRwo = {
        Name: currentElement.stundetName,
        Email: currentElement.stundetEmail,
        Mobile: currentElement.mobileNumber,
        Roll_No: currentElement.rollNo,
        Exam_Mode_Time: currentElement.examModeAndTime,
        DOB: currentElement.dateOfBirth,
        Class_stream: currentElement.classAndStream,
        Guardian_Name: currentElement.GuardianName,
        Exam_Date: currentElement.ExamDate,
        Payment_Status: currentElement.isPaid ? "Paid" : "Not Paid",
      };
      dataToExport.push(oneRwo);
    });

  return (
    <>
            <div className={classes.container}>
            <div className={classes.header}>
        <CSVLink  filename="REGISTERED_STUDENT_DETAILS" data={dataToExport} target="_blank" style={{textDecoration:'none'}}>
          <Button variant="contained" style={{textDecoration:'none'}}>Download Student Details</Button>
        </CSVLink>
      </div>
      <table className={classes.table}>
        <tr className={classes.tableRow}>
          <th className={classes.tableElement}>Name</th>
          <th className={classes.tableElement}>Email</th>
          <th className={classes.tableElement}>Mobile</th>
          <th className={classes.tableElement}>Roll No</th>
          <th className={classes.tableElement}>Exam Mode And Time</th>
          <th className={classes.tableElement}>DOB</th>
          <th className={classes.tableElement}>Class And Stream</th>
          <th className={classes.tableElement}>Guardian Name</th>
          <th className={classes.tableElement}>Exam Date</th>
          <th className={classes.tableElement}>Payment Status</th>
        </tr>
        {data.data &&
          data.data.map((currentData, index) => {
            return (
              <tr key={index}>
                <td className={classes.tableElement}>
                    {currentData.stundetName}
                </td>
                <td className={classes.tableElement}>
                {currentData.studentEmail}
                </td>
                <td className={classes.tableElement}>
                {currentData.mobileNumber}
                </td>
                <td className={classes.tableElement}>
                {currentData.rollNo}
                </td>
                <td className={classes.tableElement}>
                {currentData.examModeAndTime}

                </td>
                <td className={classes.tableElement}>
                {currentData.dateOfBirth}
                </td>
                <td className={classes.tableElement}>
                {currentData.classAndStream}
                </td>
                <td className={classes.tableElement}>
                {currentData.GuardianName}
                </td>
                <td className={classes.tableElement}>
                {currentData.ExamDate}
                </td>
                <td className={classes.tableElement}>
                {currentData.isPaid ? "Paid" : "Not Paid"} 
                </td>
              </tr>
            );
          })}
      </table>
    </div>
    </>
  )
}
