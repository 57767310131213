import axios from 'axios'
import {
    SUPER_ADMIN_SIGNIN_REQUEST,
    SUPER_ADMIN_SIGNIN_SUCCESS,
    SUPER_ADMIN_SIGNIN_FAILED,
    SUPER_ADMIN_LOGOUT,
    ADD_DDM_REQUEST,
    ADD_DDM_SUCCESS,
    ADD_DDM_FAILED,
    ADD_DDE_REQUEST,
    ADD_DDE_SUCCESS,
    ADD_DDE_FAILED,
    GET_ALL_DDE_REQUEST,
    GET_ALL_DDE_SUCCESS,
    GET_ALL_DDE_FAILED,
    DELETE_DDE_REQUEST,
    DELETE_DDE_SUCCESS,
    DELETE_DDE_FAILED,
    GET_DDM_ACTIVITY_REQUEST,
    GET_DDM_ACTIVITY_SUCCESS,
    GET_DDM_ACTIVITY_FAILED,
    GET_REGISTER_STUDENT_DETAILS_REQUEST,
    GET_REGISTER_STUDENT_DETAILS_SUCCESS,
    GET_REGISTER_STUDENT_DETAILS_FAILED,
    GET_ALL_CONVERTED_SCHOOL_REQUEST,
    GET_ALL_CONVERTED_SCHOOL_SUCCESS,
    GET_ALL_CONVERTED_SCHOOL_FAILED,
    GET_DISTRICT_FOR_CONVERTED_SCHOOL_REQUEST,
    GET_DISTRICT_FOR_CONVERTED_SCHOOL_SUCCESS,
    GET_DISTRICT_FOR_CONVERTED_SCHOOL_FAILED,
    ADD_VENUE_REQUEST,
    ADD_VENUE_SUCCESS,
    ADD_VENUE_FAILED,
    ADD_STUDENT_MARKS_REQUEST,
    ADD_STUDENT_MARKS_SUCCESS,
    ADD_STUDENT_MARKS_FAILED,
    GET_STUDENT_MARKS_REQUEST,
    GET_STUDENT_MARKS_SUCCESS,
    GET_STUDENT_MARKS_FAILED,
    SEND_STUDENT_RESULT_REQUEST,
    SEND_STUDENT_RESULT_SUCCESS,
    SEND_STUDENT_RESULT_FAILED,
  } from '../constants/superAdminConstants'





  const localStorage = window.localStorage
const API =  process.env.REACT_APP_NODE_API
const TRACK_API = process.env.REACT_APP_BDM_TRACKING_API


  // signIn super admin
  export const signInSuperAdmin = (email,password) => async (dispatch) => {
    try {
      dispatch({
        type: SUPER_ADMIN_SIGNIN_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const body = {
        email,
        password
      }
      const { data } = await axios.post(`${API}/public/super-admin/auth/login`, body, config)
      dispatch({
        type: SUPER_ADMIN_SIGNIN_SUCCESS,
        payload: data
      })
      localStorage.setItem('superAdminInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: SUPER_ADMIN_SIGNIN_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }


  // user logout
export const logoutUser = () => (dispatch) => {
    if (localStorage.getItem('superAdminInfo')) {
      localStorage.removeItem('superAdminInfo')
    }
    dispatch({
      type: SUPER_ADMIN_LOGOUT
    })
  }


  // update ddm
  export const updateDdm = (district,DdmName) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_DDM_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const body = {
        district,
        DdmName
      }
      const { data } = await axios.put(`${API}/private/super-admin/update-single-ddm`, body, config)
      dispatch({
        type: ADD_DDM_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: ADD_DDM_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }


  // add new dde
  export const addNewDde = (district,DdeName) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_DDE_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const body = {
        district,
        DdeName
      }
      const { data } = await axios.post(`${API}/private/super-admin/add-dde`, body, config)
      dispatch({
        type: ADD_DDE_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: ADD_DDE_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }


  // get all DDE
  export const getAllDDE = (district) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_DDE_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const { data } = await axios.get(`${API}/private/super-admin/get-district-dde/${district}`, config)
      dispatch({
        type: GET_ALL_DDE_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_DDE_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

  // delete single DDE
  export const deleteDDE = (district,DdeName) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_DDE_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const body ={
        district,
        DdeName
      }
      const { data } = await axios.put(`${API}/private/super-admin/delete-dde`, body,config)
      dispatch({
        type: DELETE_DDE_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: DELETE_DDE_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

  
  // Get all DDM and DDE login logout activity
  export const getDDMActivity = (onlyName) => async (dispatch) => {
    try {
      dispatch({
        type: GET_DDM_ACTIVITY_REQUEST
      })

      const name = onlyName ? onlyName : '';
  
      const config = {
        'Content-Type': 'application/json'
      }
      const { data } = await axios.get(`${TRACK_API}/private/user-management/get-all-bdm-details?onlyName=${name}`,config)
      dispatch({
        type: GET_DDM_ACTIVITY_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: GET_DDM_ACTIVITY_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

  // Get all register student details to generate report
  export const getRegisterStudentDetails = () => async (dispatch) => {
    try {
      dispatch({
        type: GET_REGISTER_STUDENT_DETAILS_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const { data } = await axios.get(`${API}/private/super-admin/get-registered-student-details`,config)
      dispatch({
        type: GET_REGISTER_STUDENT_DETAILS_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: GET_REGISTER_STUDENT_DETAILS_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

  // get all converted school
  export const getAllConvertedSchool = () => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_CONVERTED_SCHOOL_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const { data } = await axios.get(`${API}/private/super-admin/get-all-school-list`,config)
      dispatch({
        type: GET_ALL_CONVERTED_SCHOOL_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_CONVERTED_SCHOOL_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

  // get district for converted school
  export const getDistrictForConvertedSchool = (schoolName) => async (dispatch) => {
    try {
      dispatch({
        type: GET_DISTRICT_FOR_CONVERTED_SCHOOL_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const { data } = await axios.get(`${API}/private/super-admin/get-school-district/?schoolName=${schoolName}`,config)
      dispatch({
        type: GET_DISTRICT_FOR_CONVERTED_SCHOOL_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: GET_DISTRICT_FOR_CONVERTED_SCHOOL_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }


  // Add venue by super admin
  export const addVenue = (schoolName,venue,venueCapacity) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_VENUE_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const body ={
        schoolName,
        venue,
        venueCapacity
      }
      const { data } = await axios.post(`${API}/private/super-admin/add-exam-venue`,body,config)
      dispatch({
        type: ADD_VENUE_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: ADD_VENUE_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }



export const getStudentDetails = (studentInfo) => async (dispatch) => {  
  try {
    dispatch({
      type: GET_REGISTER_STUDENT_DETAILS_REQUEST
    })

    const config = {
      'Content-Type': 'application/json'
    }
    const { data } = await axios.get(`${API}/private/student/activity/get-student-details/${studentInfo}`,config)
    dispatch({
      type: GET_REGISTER_STUDENT_DETAILS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_REGISTER_STUDENT_DETAILS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const getAllStudentDetails = () => async (dispatch) => {  
  try {
    dispatch({
      type: GET_REGISTER_STUDENT_DETAILS_REQUEST
    })

    const config = {
      'Content-Type': 'application/json'
    }
    const { data } = await axios.get(`${API}/private/student/activity/get-all-student-details`,config)
    dispatch({
      type: GET_REGISTER_STUDENT_DETAILS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_REGISTER_STUDENT_DETAILS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const getStudentMarks = (studentId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_STUDENT_MARKS_REQUEST
    })

    const config = {
      'Content-Type': 'application/json'
    }
    const { data } = await axios.get(`${API}/private/student/activity/get-marks/${studentId}`,config)
    console.log('marks:',data)
    dispatch({
      type: GET_STUDENT_MARKS_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log('error:',error);
    dispatch({
      type: GET_STUDENT_MARKS_FAILED,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message
    })
  }
}

export const addStudentMarks = (studentId,marks) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_STUDENT_MARKS_REQUEST
    })

    const config = {
      'Content-Type': 'application/json'
    }
    const body ={
      id: studentId,
      marks
    }
    const { data } = await axios.post(`${API}/private/student/activity/add-marks`,body,config)
    console.log(data) // check the data in console
    dispatch({
      type: ADD_STUDENT_MARKS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: ADD_STUDENT_MARKS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const sendResultSms = (phoneNumber) => async (dispatch) => {
  try{
    dispatch({
      type: SEND_STUDENT_RESULT_REQUEST
    })

    const config = {
      'Content-Type': 'application/json'
    }
    const body ={
      phoneNumber
    }
    const { data } = await axios.post(`${API}/public/student/result/send-result-sms`,body,config)
    console.log(data);
    dispatch({
      type: SEND_STUDENT_RESULT_SUCCESS,
      payload: data
    })
  }
  catch(error){
    dispatch({
      type: SEND_STUDENT_RESULT_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}