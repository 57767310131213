import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { signInSuperAdmin } from '../../redux/actions/superAdminAction';


export const SuperAdminLogic = () => {
    const { error, superAdminInfo } = useSelector((state) => state.superAdminSignin)

    console.log('====================================');
    console.log(superAdminInfo);
    console.log('====================================');

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();



    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
        }
    }, [error])



    useEffect(() => {
        if (superAdminInfo) {
            navigate('/super-admin-home')
        }
    }, [superAdminInfo])


    const loginHander = () => {
        if (email && password) {
            dispatch(signInSuperAdmin(email, password))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all the fields',
            })
        }
    }


    return {
        email,
        setEmail,
        password,
        setPassword,
        loginHander
    }
}