import { Paper, Button } from '@mui/material';
import React from 'react'
import useStyles from './Homestyles';
import { useNavigate } from 'react-router-dom';

const SuperAdminHome = () => {
    const classes = useStyles();
    const navigate = useNavigate();

  
    return (

        <div className={classes.container}>
            <Paper className={classes.paper} sx={{ boxShadow: '0 0 .3rem .2rem #185694', }}>
                <Button onClick={() => { navigate('/super-admin-venue') }} className={classes.button} sx={{ fontSize: "1.5rem", padding: "1.8rem", fontWeight: "600" }}>Enter Exam Venue</Button>
            </Paper>
            <Paper className={classes.paper} sx={{ boxShadow: '0 0 .3rem .2rem #185694', }} >
                <Button onClick={() => { navigate('/super-admin-date') }} className={classes.button} sx={{ fontSize: "1.5rem", padding: "1.8rem", fontWeight: "600" }}>Enter Exam Date</Button>
            </Paper>
            <Paper className={classes.paper} sx={{ boxShadow: '0 0 .3rem .2rem #185694', }} >
                <Button onClick={() => { navigate('/super-admin-bdm') }} className={classes.button} sx={{ fontSize: "1.5rem", padding: "1.8rem", fontWeight: "600" }}>BDM Details</Button>
            </Paper>
            <Paper className={classes.paper} sx={{ boxShadow: '0 0 .3rem .2rem #185694', }} >
                <Button onClick={() => { navigate('/registered-student-details') }} className={classes.button} sx={{ fontSize: "1.5rem", padding: "1.8rem", fontWeight: "600" }}>Student Details</Button>
            </Paper>
            <Paper className={classes.paper} sx={{ boxShadow: '0 0 .3rem .2rem #185694', }} >
                <Button onClick={() => { navigate('/super-admin-ddm') }} className={classes.button} sx={{ fontSize: "1.5rem", padding: "1.8rem", fontWeight: "600" }}>Add/Update DDM</Button>
            </Paper>
            <Paper className={classes.paper} sx={{ boxShadow: '0 0 .3rem .2rem #185694', }} >
                <Button onClick={() => { navigate('/super-admin-dde') }} className={classes.button} sx={{ fontSize: "1.5rem", padding: "1.8rem", fontWeight: "600" }}>Add DDE</Button>
            </Paper>
            <Paper className={classes.paper} sx={{ boxShadow: '0 0 .3rem .2rem #185694', }} >
                <Button onClick={() => { navigate('/super-admin-dde-edit') }} className={classes.button} sx={{ fontSize: "1.5rem", padding: "1.8rem", fontWeight: "600" }}>Update DDE</Button>
            </Paper>
            <Paper className={classes.paper} sx={{ boxShadow: '0 0 .3rem .2rem #185694', }} >
                <Button onClick={() => { navigate('/track-bdms') }} className={classes.button} sx={{ fontSize: "1.5rem", padding: "1.8rem", fontWeight: "600" }}>Track All DDM DDE</Button>
            </Paper>
            <Paper className={classes.paper} sx={{ boxShadow: '0 0 .3rem .2rem #185694', }} >
                <Button onClick={() => { navigate('/track-single-ddm-dde') }} className={classes.button} sx={{ fontSize: "1.5rem", padding: "1.8rem", fontWeight: "600" }}>Track Single DDM DDE</Button>
            </Paper>
            <Paper className={classes.paper} sx={{ boxShadow: '0 0 .3rem .2rem #185694', }} >
                <Button onClick={() => { navigate('/add-student-marks') }} className={classes.button} sx={{ fontSize: "1.5rem", padding: "1.8rem", fontWeight: "600" }}>Add Marks</Button>
            </Paper>
        </div>
    )
}

export default SuperAdminHome