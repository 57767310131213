import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    container:{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
    },
    admitViewer:{
        width: "100%",
        // height: "100vh",
        [theme.breakpoints.down('sm')]:{
            width: "100%",
            height: "100%",
        }
    },
    paper: {
        width: "80%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: "3rem",
        marginTop: "2rem",
        [theme.breakpoints.down('sm')]:{
            width: "70%",
            fontSize: "1.5rem",
        }
    },
    paper2: {
        width: "80%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: "3rem",
        marginTop: "2rem",
        [theme.breakpoints.down('sm')]:{
            width: "70%",
            fontSize: "1.5rem",
        }
    },
    text: {
        padding: theme.spacing(1.3),
        fontSize: "2rem",
        [theme.breakpoints.down('sm')]:{
            width: "100%",
            fontSize: "2rem",
        }
    },
    submit: {
        width: "30%",
        margin: "1rem",
        padding: "1.4rem",
        borderRadius: "1.2rem",
        backgroundColor: "#185694",
        fontSize: "1.8rem",
        [theme.breakpoints.down('sm')]:{
            width: "100%",
            fontSize: "1rem"
        }
    },
}));