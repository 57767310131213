import React from 'react'
import { Typography, Button, FormLabel } from '@material-ui/core';
import useStyles from './styles';
import {LoginLogic} from "./login.logic"


import illustration from '../../images/login-illustration.jpg';

//define initial state for login form


const LogIn = () => {
    const classes = useStyles();
    const {
        handleSubmit,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        uniqueId,
        setUniqueId,
        loginHandler,
    } = LoginLogic()
    return (
        // Login Form
        <div className={classes.container}>

            <div className={classes.paper}>
                <form onSubmit={handleSubmit} className={classes.form} autoComplete='off'>

                    <Typography variant="h4" className={classes.text}>
                        Login As Student
                    </Typography>
                    <div className={classes.group}>
                        <FormLabel className={classes.label}>First Name:</FormLabel>
                        <input name="firstName" className={classes.box}
                         value={firstName}
                         onChange={(e)=>{setFirstName(e.target.value)}} required type="text" />
                    </div>
                    <div className={classes.group}>
                        <FormLabel className={classes.label}>Last Name:</FormLabel>
                        <input name="lastName" className={classes.box}
                        value={lastName}
                        onChange={(e)=>{setLastName(e.target.value)}} 
                        required type="text" />
                    </div>
                    <div className={classes.group}>
                        <FormLabel className={classes.label}>Unique ID:</FormLabel>
                        <input type="text" name="country_code" className={classes.box} 
                        value={uniqueId}
                        onChange={(e)=>{setUniqueId(e.target.value)}} 
                        required pattern="[1-9]{1}[0-9]{9}" />

                    </div>

                    <Button onClick={loginHandler} type="submit" variant='contained' color="primary" className={classes.submit}>Login</Button>


                </form>




            </div>
            <div className={classes.paper1}>

                <img to="/" src={illustration} alt="icon" className={classes.image} width="100%" />

            </div>

        </div>
    );
};
export default LogIn;