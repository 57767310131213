              ///    HOLD CUSTOM DATA FOR DDE FORM SUBMISSION   ///
              export const holdCustomStateReducer = (
                state = {
                    data:{
                        district: null,
                        block: null,
                        DDM: null,
                        DDE:null,
                        schoolName:null,
                        schoolType:null,
                        class:null,
                        board:null,
                        principalContact:null,
                    }
                },
                action
              ) => {
                switch (action.type) {
                  case "HOLD_CUSTOM_STATE":
                    return {
                       ...state, 
                       data: action.payload,
                    }
                  default:
                    return state
                }
              }