import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        border: "1.5px solid black",
        borderRadius: "0",

        '& .MuiTableCell-root': {
            padding: "0.9rem",
        },
    },
    result: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "2rem 4rem",
        alignItems: "center",
        width: "100%",
        minHeight: "140rem",
        // height: "100%",
        // backgroundColor: "#f5f5a5",
        gap: "2.5rem",
        [theme.breakpoints.down("sm")]: {
            height: "100%",
        },
    },
    bold: {
        fontWeight: "bold",
    },
    serif:{
        fontFamily: "serif",
    },
    uppercase: {
        textTransform: "uppercase",
    },
    sm:{
        fontSize: "1.8rem",
    },
    flex_col: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    },
    text_center: {
        textAlign: "center",
    },
    text_left: {
        textAlign: "left",
    },
    table: {
        "& .MuiTableCell-root": {
            border: "1.5px solid rgb(0,0,0)"
          }
    },
    table_border: {
        border: "1.5px solid rgb(0,0,0)"
    },
    border: {
        border: "1.5px solid black",
        padding: "0.8rem",
    },
    border_top: {
        padding: "2rem",
        borderTop: "1.5px solid black",
    },
    self_start: {
        alignSelf: "flex-start",
        width: "100%",
    },
    flex_1: {
        flex: 1,
    },
    flex_20: {
        flexBasis: '20%',
    },
    width_full: {
        width: "100%",
    },
}));