import React, { useState } from "react";
import {
  footerCol1,
  footerCol2,
  footerCol3,
  footerCol4,
  socials,
} from "./FooterLinks";
import "./Footer.css";
import CheckIcon from '@mui/icons-material/Check';

function Footer() {
  const [email, setEmail] = useState("");
  const [showSubsMsg, setShowSubsMsg] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setEmail("");
    setShowSubsMsg(true);
    setTimeout(() => {
      setShowSubsMsg(false);
    }, 4000);
  };
  return (
    <div className="footer1">
      <form className="form" onSubmit={handleSubmit}>
        <label className="form-label">Subscribe to Our Newsletter</label>
        <div className="form-input">
          <input
            className="email"
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Type your email id here..."
            required
          />
          <button className="form-btn" type="submit">
            Subscribe Now
          </button>
        </div>
      </form>
      {showSubsMsg && (
        <div className="subscribe-msg">
          <CheckIcon /> Brace yourself! Enlightenment is on its way.
        </div>
      )}
      <div className="footer-content">
        <div className="footer-nav">
          <div className="footer-cols">
            <div className="footer-col">
              {footerCol1.map((link, idx) => (
                <a
                  target={link?.isOtherSite && "_blank"}
                  key={idx}
                  href={link.link}
                >
                  {link.name}
                </a>
              ))}
            </div>
            <div className="footer-col">
              {footerCol2.map((link, idx) => (
                <a
                  target={link?.isOtherSite && "_blank"}
                  key={idx}
                  href={link.link}
                >
                  {link.name}
                </a>
              ))}
            </div>
            <div className="footer-col">
              {footerCol3.map((link, idx) => (
                <a
                  target={link?.isOtherSite && "_blank"}
                  key={idx}
                  href={link.link}
                >
                  {link.name}
                </a>
              ))}
            </div>
            <div className="footer-col">
              {footerCol4.map((link, idx) => (
                <a
                  target={link?.isOtherSite && "_blank"}
                  key={idx}
                  href={link.link}
                >
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          <div className="socials">
            <div className="socials-heading">Our Socials</div>
            <div className="social-icons">
              {socials.map((social, idx) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  key={idx}
                  href={social.link}
                  title={social.name}
                  className="social-icon"
                >
                  <img src={social.icon} alt={social.name} />
                </a>
              ))}
            </div>
            <div className="email-contact">
              <div>Email: hey@matricula.co.in</div>
              <div className="contact">
                Contact No:
                <div>
                  <div>+91 81004 82638</div>
                  <div>+91 74397 09486</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="copyright">
          Copyright &copy; 2017 - {new Date().getFullYear()} Roasted Sage Private Limited | Maintained and Developed by&nbsp;
          <a href="https://serein.io/" target="_blank" rel="noreferrer">
            Serein.io
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
