import {
    ADMIN_SIGNIN_REQUEST,
    ADMIN_SIGNIN_SUCCESS,
    ADMIN_SIGNIN_FAILED,
    ADMIN_LOGOUT,
    OTP_VERIFY_REQUEST,
    OTP_VERIFY_SUCCESS,
    OTP_VERIFY_FAILED,
    UPLOAD_CSV_TO_CLOUD_REQUEST,
    UPLOAD_CSV_TO_CLOUD_SUCCESS,
    UPLOAD_CSV_TO_CLOUD_FAILED,
    REMOVE_SHEET,
    UPLOAD_CSV_TO_MONGO_DB_REQUEST,
    UPLOAD_CSV_TO_MONGO_DB_SUCCESS,
    UPLOAD_CSV_TO_MONGO_DB_FAILED,
    GET_BLOCKS_REQUEST,
    GET_BLOCKS_SUCCESS,
    GET_BLOCKS_FAILED,
    GET_SCHOOLS_REQUEST,
    GET_SCHOOLS_SUCCESS,
    GET_SCHOOLS_FAILED,
    ADD_STUDENT_INFO_REQUEST,
    ADD_STUDENT_INFO_SUCCESS,
    ADD_STUDENT_INFO_FAILED,
  } from '../constants/adminConstants'  
  
  
      ///    SIGNIN REDUCER    ///
      export const adminSigninReducer = (
          state = {
              loading: false,
              data: null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case ADMIN_SIGNIN_REQUEST:
              return {
                 ...state, 
                 data: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case ADMIN_SIGNIN_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                data: action.payload
                
              }
            case ADMIN_SIGNIN_FAILED:
              return {
                  ...state,
                loading: false,
                data: null,
                isAuthenticated:false,
                error: action.payload
              }
            default:
              return state
          }
        }



              ///    VERIFY OTP    ///
      export const verifyOtpReducer = (
        state = {
            loading: false,
            adminInfo: null,
            error: null,
            isAuthenticated:false
        },
        action
      ) => {
        switch (action.type) {
          case OTP_VERIFY_REQUEST:
            return {
               ...state, 
               adminInfo: null,
               error: null,
               isAuthenticated:false,
               loading: true
            }
          case OTP_VERIFY_SUCCESS:
            return {
                ...state,
              loading: false,
              isAuthenticated: true,
              error: null,
              adminInfo: action.payload
              
            }
          case OTP_VERIFY_FAILED:
            return {
                ...state,
              loading: false,
              adminInfo: null,
              isAuthenticated:false,
              error: action.payload
            }
      
            case ADMIN_LOGOUT:
              return {
                adminInfo: null
              }
          default:
            return state
        }
      }


              ///    UPLOAD SHEET TO CLOUD    ///
      export const uploadCsvToCloudReducer = (
        state = {
            loading: false,
            sheetUrl: null,
            error: null,
            isAuthenticated:false
        },
        action
      ) => {
        switch (action.type) {
          case UPLOAD_CSV_TO_CLOUD_REQUEST:
            return {
               ...state, 
               sheetUrl: null,
               error: null,
               isAuthenticated:false,
               loading: true
            }
          case UPLOAD_CSV_TO_CLOUD_SUCCESS:
            return {
                ...state,
              loading: false,
              isAuthenticated: true,
              error: null,
              sheetUrl: action.payload
              
            }
          case UPLOAD_CSV_TO_CLOUD_FAILED:
            return {
                ...state,
              loading: false,
              sheetUrl: null,
              isAuthenticated:false,
              error: action.payload
            }
      
            case REMOVE_SHEET:
              return {
                sheetUrl: null
              }
          default:
            return state
        }
      }


              ///    UPLOAD SHEET DATA TO MONGO DB    ///
      export const uploadCsvToMongoDBReducer = (
        state = {
            loading: false,
            data: null,
            error: null,
            isAuthenticated:false
        },
        action
      ) => {
        switch (action.type) {
          case UPLOAD_CSV_TO_MONGO_DB_REQUEST:
            return {
               ...state, 
               data: null,
               error: null,
               isAuthenticated:false,
               loading: true
            }
          case UPLOAD_CSV_TO_MONGO_DB_SUCCESS:
            return {
                ...state,
              loading: false,
              isAuthenticated: true,
              error: null,
              data: action.payload
              
            }
          case UPLOAD_CSV_TO_MONGO_DB_FAILED:
            return {
                ...state,
              loading: false,
              data: null,
              isAuthenticated:false,
              error: action.payload
            }
          default:
            return state
        }
      }

      
              ///    GET ALL BLOCKS FOR A DISTRICT    ///
      export const getBlocksReducer = (
        state = {
            loading: false,
            data: null,
            error: null,
            isAuthenticated:false
        },
        action
      ) => {
        switch (action.type) {
          case GET_BLOCKS_REQUEST:
            return {
               ...state, 
               data: null,
               error: null,
               isAuthenticated:false,
               loading: true
            }
          case GET_BLOCKS_SUCCESS:
            return {
                ...state,
              loading: false,
              isAuthenticated: true,
              error: null,
              data: action.payload
              
            }
          case GET_BLOCKS_FAILED:
            return {
                ...state,
              loading: false,
              data: null,
              isAuthenticated:false,
              error: action.payload
            }
          default:
            return state
        }
      }



              ///    GET ALL SCHOOLS FOR A BLOCK    ///
      export const getSchoolsReducer = (
        state = {
            loading: false,
            data: null,
            error: null,
            isAuthenticated:false
        },
        action
      ) => {
        switch (action.type) {
          case GET_SCHOOLS_REQUEST:
            return {
               ...state, 
               data: null,
               error: null,
               isAuthenticated:false,
               loading: true
            }
          case GET_SCHOOLS_SUCCESS:
            return {
                ...state,
              loading: false,
              isAuthenticated: true,
              error: null,
              data: action.payload
              
            }
          case GET_SCHOOLS_FAILED:
            return {
                ...state,
              loading: false,
              data: null,
              isAuthenticated:false,
              error: action.payload
            }
          default:
            return state
        }
      }


              ///    ADD STUDENT DATA    ///
      export const addStudentInfoReducer = (
        state = {
            loading: false,
            data: null,
            error: null,
            isAuthenticated:false
        },
        action
      ) => {
        switch (action.type) {
          case ADD_STUDENT_INFO_REQUEST:
            return {
               ...state, 
               data: null,
               error: null,
               isAuthenticated:false,
               loading: true
            }
          case ADD_STUDENT_INFO_SUCCESS:
            return {
                ...state,
              loading: false,
              isAuthenticated: true,
              error: null,
              data: action.payload
              
            }
          case ADD_STUDENT_INFO_FAILED:
            return {
                ...state,
              loading: false,
              data: null,
              isAuthenticated:false,
              error: action.payload
            }
          default:
            return state
        }
      }