export const ADMIN_SIGNIN_REQUEST = 'ADMIN_SIGNIN_REQUEST'
export const ADMIN_SIGNIN_SUCCESS = 'ADMIN_SIGNIN_SUCCESS'
export const ADMIN_SIGNIN_FAILED = 'ADMIN_SIGNIN_FAILED'
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT'

export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST'
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS'
export const OTP_VERIFY_FAILED = 'OTP_VERIFY_FAILED'

export const UPLOAD_CSV_TO_CLOUD_REQUEST = 'UPLOAD_CSV_TO_CLOUD_REQUEST'
export const UPLOAD_CSV_TO_CLOUD_SUCCESS = 'UPLOAD_CSV_TO_CLOUD_SUCCESS'
export const UPLOAD_CSV_TO_CLOUD_FAILED = 'UPLOAD_CSV_TO_CLOUD_FAILED'
export const REMOVE_SHEET = 'REMOVE_SHEET'


export const UPLOAD_CSV_TO_MONGO_DB_REQUEST = 'UPLOAD_CSV_TO_MONGO_DB_REQUEST'
export const UPLOAD_CSV_TO_MONGO_DB_SUCCESS = 'UPLOAD_CSV_TO_MONGO_DB_SUCCESS'
export const UPLOAD_CSV_TO_MONGO_DB_FAILED = 'UPLOAD_CSV_TO_MONGO_DB_FAILED'


export const GET_BLOCKS_REQUEST = 'GET_BLOCKS_REQUEST'
export const GET_BLOCKS_SUCCESS = 'GET_BLOCKS_SUCCESS'
export const GET_BLOCKS_FAILED = 'GET_BLOCKS_FAILED'


export const GET_SCHOOLS_REQUEST = 'GET_SCHOOLS_REQUEST'
export const GET_SCHOOLS_SUCCESS = 'GET_SCHOOLS_SUCCESS'
export const GET_SCHOOLS_FAILED = 'GET_SCHOOLS_FAILED'


export const ADD_STUDENT_INFO_REQUEST = 'ADD_STUDENT_INFO_REQUEST'
export const ADD_STUDENT_INFO_SUCCESS = 'ADD_STUDENT_INFO_SUCCESS'
export const ADD_STUDENT_INFO_FAILED = 'ADD_STUDENT_INFO_FAILED'