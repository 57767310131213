import axios from 'axios'
import {
    ADMIN_SIGNIN_REQUEST,
    ADMIN_SIGNIN_SUCCESS,
    ADMIN_SIGNIN_FAILED,
    ADMIN_LOGOUT,
    OTP_VERIFY_REQUEST,
    OTP_VERIFY_SUCCESS,
    OTP_VERIFY_FAILED,
    UPLOAD_CSV_TO_CLOUD_REQUEST,
    UPLOAD_CSV_TO_CLOUD_SUCCESS,
    UPLOAD_CSV_TO_CLOUD_FAILED,
    REMOVE_SHEET,
    UPLOAD_CSV_TO_MONGO_DB_REQUEST,
    UPLOAD_CSV_TO_MONGO_DB_SUCCESS,
    UPLOAD_CSV_TO_MONGO_DB_FAILED,
    GET_BLOCKS_REQUEST,
    GET_BLOCKS_SUCCESS,
    GET_BLOCKS_FAILED,
    GET_SCHOOLS_REQUEST,
    GET_SCHOOLS_SUCCESS,
    GET_SCHOOLS_FAILED,
    ADD_STUDENT_INFO_REQUEST,
    ADD_STUDENT_INFO_SUCCESS,
    ADD_STUDENT_INFO_FAILED,
  } from '../constants/adminConstants'





  const localStorage = window.localStorage
const API =  process.env.REACT_APP_NODE_API


  // signIn user
  export const signInAdmin = (number) => async (dispatch) => {
    try {
      dispatch({
        type: ADMIN_SIGNIN_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const body = {
        number
      }
      const { data } = await axios.post(`${API}/public/admin/auth/login`, body, config)
      dispatch({
        type: ADMIN_SIGNIN_SUCCESS,
        payload: data
      })
    //   localStorage.setItem('adminInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: ADMIN_SIGNIN_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }


  // user logout
export const logoutUser = () => (dispatch) => {
    if (localStorage.getItem('userInfo')) {
      localStorage.removeItem('userInfo')
    }
    dispatch({
      type: ADMIN_LOGOUT
    })
  }




    // otp verify
    export const verifyOtp = (otp) => async (dispatch,getState) => {
        try {
          dispatch({
            type: OTP_VERIFY_REQUEST
          })

          const {
            sentOtp: { data:adminId }
          } = getState()

          console.log(adminId)
      
          const config = {
            'Content-Type': 'application/json'
          }
          const body = {
            id: adminId.data._id,
            otp
          }
          const { data } = await axios.post(`${API}/public/admin/auth/match-otp`, body, config)
          dispatch({
            type: OTP_VERIFY_SUCCESS,
            payload: data
          })
           localStorage.setItem('adminInfo', JSON.stringify(data))
        } catch (error) {
          dispatch({
            type: OTP_VERIFY_FAILED,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message
          })
        }
      }



    // UPLOAD CSV TO CLOUD
    export const uploadCsvToCloud = (formData) => async (dispatch,getState) => {
        try {
          dispatch({
            type: UPLOAD_CSV_TO_CLOUD_REQUEST
          })

          const {
            sentOtp: { data:adminId }
          } = getState()

          console.log(adminId)
      
          const config = {
            'Content-Type': 'application/json'
          }
          // const body = {
          //   formData
          // }
          const { data } = await axios.post(`${API}/private/admin/upload-sheet-to-cloud`, formData, config)
          dispatch({
            type: UPLOAD_CSV_TO_CLOUD_SUCCESS,
            payload: data.data.Location
          })
           localStorage.setItem('sheetUrl', JSON.stringify(data.data.Location))
        } catch (error) {
          dispatch({
            type: UPLOAD_CSV_TO_CLOUD_FAILED,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message
          })
        }
      }

        // remove sheet url
export const removeSheet = () => (dispatch) => {
  if (localStorage.getItem('sheetUrl')) {
    localStorage.removeItem('sheetUrl')
  }
  dispatch({
    type: REMOVE_SHEET
  })
}



    // UPLOAD CSV DATA TO MONGO DB
    export const uploadCsvToMongoDB = (sheetUrl,district) => async (dispatch,getState) => {
      console.log(sheetUrl)
      try {
        dispatch({
          type: UPLOAD_CSV_TO_MONGO_DB_REQUEST
        })

        const {
          sentOtp: { data:adminId }
        } = getState()

        console.log(adminId)
    
        const config = {
          'Content-Type': 'application/json'
        }
        const body = {
          sheetUrl,
          district
        }
        const { data } = await axios.post(`${API}/private/admin/add-data`, body, config)
        dispatch({
          type: UPLOAD_CSV_TO_MONGO_DB_SUCCESS,
          payload: data
        })
      } catch (error) {
        dispatch({
          type: UPLOAD_CSV_TO_MONGO_DB_FAILED,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
        })
      }
    }


    // GET ALL THE BLOCKS FOR A DISTRICT
    export const getBlocks = (district) => async (dispatch) => {
      try {
        dispatch({
          type: GET_BLOCKS_REQUEST
        })
        const config = {
          'Content-Type': 'application/json'
        }
        const body = {
          district
        }
        const { data } = await axios.post(`${API}/private/admin/get-details-to-add-student-data`, body, config)
        dispatch({
          type: GET_BLOCKS_SUCCESS,
          payload: data
        })
      } catch (error) {
        dispatch({
          type: GET_BLOCKS_FAILED,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
        })
      }
    }

    // GET ALL THE SCHOOLS FOR A BLOCK
    export const getSchools = (district,block) => async (dispatch) => {
      try {
        dispatch({
          type: GET_SCHOOLS_REQUEST
        })
        const config = {
          'Content-Type': 'application/json'
        }
        const body = {
          district,
          block
        }
        const { data } = await axios.post(`${API}/private/admin/get-details-to-add-student-data`, body, config)
        dispatch({
          type: GET_SCHOOLS_SUCCESS,
          payload: data
        })
      } catch (error) {
        dispatch({
          type: GET_SCHOOLS_FAILED,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
        })
      }
    }


    // ADD STUDENT DATA
    export const addStudentInfo = (
        district, 
        block, 
        ddm, 
        dde, 
        schoolType,
        studentClass , 
        board,
        schoolName,
        firstName,
        lastName,
        phoneNumber,
        AlternativeNo,
        email,
        principalContact,
        registerSubject1,
        registerSubject2,
        registerSubject3,
        registerSubject4,
        registerSubject5,
        registerSubject6,
        registerSubject7,
        amountTakenForRegistration
    ) => async (dispatch) => {
      try {
        dispatch({
          type: ADD_STUDENT_INFO_REQUEST
        })
        const config = {
          'Content-Type': 'application/json'
        }
        const body = {
          district, 
          block, 
          ddm, 
          dde, 
          schoolType,
          studentClass , 
          board,
          schoolName,
          firstName,
          lastName,
          phoneNumber,
          AlternativeNo,
          email,
          principalContact,
          registerSubject1,
          registerSubject2,
          registerSubject3,
          registerSubject4,
          registerSubject5,
          registerSubject6,
          registerSubject7,
          amountTakenForRegistration
        }
        const { data } = await axios.post(`${API}/private/admin/v02/add-data`, body, config)
        dispatch({
          type: ADD_STUDENT_INFO_SUCCESS,
          payload: data
        })
      } catch (error) {
        dispatch({
          type: ADD_STUDENT_INFO_FAILED,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
        })
      }
    }