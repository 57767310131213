import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    mainContainer: {

        margin: "auto",
        fontSize: "1.4rem",
        width: "90rem",
        height: "100rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "100%",

            fontSize: "1rem"
        },
        [theme.breakpoints.down('xs')]: {
            transform: "scale(0.9, 0.9)",
            marginTop: "-5rem",
        },

    },
    container: {
        marginTop: "2rem",
        padding: "2rem",
        width: "95%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    },
    box: {
        width: "100%",
        height: "90%",
        border: "2px solid black",
        padding: "3rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",

    },
    // logo:{
    //     position: "relative",
    //     right: "20rem",
    //     display: "block",
    //     [theme.breakpoints.down('sm')]: {
    //         right: 0
    //     },
    // },
    topBlock: {
        width:'100%',
        margin: "auto",
        display: "flex",
        // flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
        fontSize: "1.6rem",
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
        },
    },

    helperBox:{
        width:'50px',
        height:'50px',
        backgroundColor:'transparent'
    },
   
    textBlock: {
        // position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
       
    },
    toptext: {

        fontWeight: "800"
    },
    detailText: {
        margin: "1rem"
    },
    table: {
        borderCollapse: "collapse",
        width: "100%",
        fontSize: "1.5rem"

    },
    tableElement: {
        height: "2rem",
        border: ".01rem solid black",
        padding: ".8rem",
        textAlign: "center"
    },
    importantText: {
        marginTop: "2rem",
        padding: "1rem"
    },
    pText: {
        fontSize: "1.4rem",
        margin: "1rem"
    },
    listElement: {
        margin: "1.8rem",
        fontSize: "1.4rem"
    },
    signSuperBlock: {
        display: "flex",
        justifyContent: "space-between",
        margin: "5rem 2rem 1.2rem 2rem",
        textAlign: "center"

    },
    signBlock: {
        width: "30%"
    },
    signature: {
        marginBottom: "1rem",
        borderBottom: "2px solid black"
    },
    submit: {
        width: "20%",
        marginBottom: "5rem",
        padding: "1.4rem",
        borderRadius: "1.2rem",
        backgroundColor: "#185694",
        fontSize: "1.8rem",
        [theme.breakpoints.down('xs')]: {
            width: "40%",
            fontSize: "1.5rem",
            marginBottom: "0rem",
        },
    },


}));