import React, { useEffect, useState } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Popup, CircleMarker } from "react-leaflet";
// eslint-disable-next-line
// import "leaflet/dist/leaflet.css";

const API = process.env.REACT_APP_BDM_TRACKING_API;

export default function TrackBdms() {
  var helperText;
  var colorOption = {};
  const [bdmAllDetails, setBdmAllDetails] = useState([]);

  useEffect(() => {
    async function getData() {
      const { data } = await axios.get(
        `${API}/private/user-management/track-bdm`
      );
      const { BdmDetails } = data;
      setBdmAllDetails(BdmDetails);
    }
    getData();
  }, []);
  return (
    <>
      <MapContainer
      className="leaflet-container"
        center={[22.497187, 88.3241283]}
        zoom={10}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

<div  style={{ width: "100%", height: "100vh" }}>
        {bdmAllDetails &&
          bdmAllDetails.map((currentElement, index) => {
            if (currentElement.working_status) {
              colorOption = { color: "green" };
              helperText = `${currentElement.name} is working now`;
            } else {
              colorOption = { color: "red" };
              helperText = `${currentElement.name} is offline`;
            }
            return (
              <>
                  <CircleMarker
                  key={index}
                    center={[
                      parseFloat(currentElement.latitude),
                      parseFloat(currentElement.longitude),
                    ]}
                    pathOptions={colorOption}
                    radius={20}
                  >
                    <Popup>{helperText}</Popup>
                  </CircleMarker>
              </>
            );
          })}
                </div>
      </MapContainer>
    </>
  );
}
