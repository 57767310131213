import { combineReducers } from 'redux'
import { addStudentInfoReducer, adminSigninReducer, getBlocksReducer, getSchoolsReducer, uploadCsvToCloudReducer, uploadCsvToMongoDBReducer, verifyOtpReducer } from './adminReducer'
import { holdCustomStateReducer } from './customStateReducer'
import { addAndUpdateStudentMarksReducer, addNewDdeReducer, addVenueReducer, deleteDDEReducer, getAllConvertedSchoolReducer, getAllDDEReducer, getDDMActivityReducer, getDistrictForConvertedSchoolReducer, getRegisterStudentDetailsReducer, getStudentMarksReducer, sendResultSmsReducer, superAdminSigninReducer, updateDdmReducer } from './superAdminReducer'
import { userSigninReducer } from './userReducer'

const rootReducer = combineReducers({
    userSignin:userSigninReducer,
    sentOtp:adminSigninReducer,
    adminSignin:verifyOtpReducer,
    uploadCsvToCloud:uploadCsvToCloudReducer,
    uploadCsvToMongoDB:uploadCsvToMongoDBReducer,
    superAdminSignin:superAdminSigninReducer,
    getBlocks:getBlocksReducer,
    getSchools:getSchoolsReducer,
    addStudentInfo:addStudentInfoReducer,
    holdCustomState:holdCustomStateReducer,
    updateDdm:updateDdmReducer,
    addNewDde:addNewDdeReducer,
    getAllDDE:getAllDDEReducer,
    deleteDDE:deleteDDEReducer,
    getDDMActivity:getDDMActivityReducer,
    getRegisterStudentDetails:getRegisterStudentDetailsReducer,
    getAllConvertedSchool:getAllConvertedSchoolReducer,
    getDistrictForConvertedSchool:getDistrictForConvertedSchoolReducer,
    addVenue:addVenueReducer,
    studentMarks: addAndUpdateStudentMarksReducer,
    studentMarksDetails: getStudentMarksReducer,
    resultSms: sendResultSmsReducer
})

export default rootReducer
