import React, { useEffect, useState } from 'react'
import Select from "react-select";
import useStyles from "./addStyles";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button } from "@material-ui/core";

import data from "./data.json";
import { getBlocks } from '../../redux/actions/adminAction';
import { updateDdm } from '../../redux/actions/superAdminAction';
const AddDdm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: blockData } = useSelector((state) => state.getBlocks);
  const { data: addDdmData } = useSelector((state) => state.updateDdm);
  const [ddm, setDdm] = useState("");
  // const [checked, setChecked] = useState(false);
  const [edit, setEdit] = useState(false);
  const [district, setDistrict] = useState(null);
  const handleCheck = () => {
    // setChecked(!checked)
    setEdit(!edit);
  }

  useEffect(() => {
    setDdm(blockData ? blockData.data.BDM : "")
  }, [blockData])

  useEffect(() => {
    if(addDdmData){
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: "DDM updated successfully",
      })
    }

  }, [addDdmData])
  

  const handleDistrict = (e) => {
    dispatch(getBlocks(e.district.toLowerCase()));
    setDistrict(e);
    // setBlock(null);
  };
  const handleSubmit = (e) => {
    if(ddm || district !== null){
      dispatch(updateDdm(district.district.toLowerCase(), ddm.toLowerCase()));
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Please Provide a ddm Name and a district",
      })
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.paper}>
      <Typography variant="h4" className={classes.text}>
            ADD/UPDATE DDM
          </Typography>
        <Select
          className={classes.dropdown}
          placeholder="Select District"
          value={district}
          options={data}
          onChange={(e) => handleDistrict(e)}
          getOptionLabel={(x) => x.district}
          getOptionValue={(x) => x.district}
        />
        <div className={classes.group}>
          <label className={classes.label}>DDM:</label>
          {
              <input
                className={classes.input}
                disabled={!edit}
                value={ddm}
                onChange={(e)=>{setDdm(e.target.value)}}
              />
          }

        </div>
        <div style={{ fontSize: "1.5rem", }}>
          <label htmlFor="check" style={{ color: "grey" }}>Edit </label>

          <input type="checkbox"  onChange={handleCheck} name="check" style={{ marginRight: ".5rem" }} />
        </div>
        {
          edit ?
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
             
            >
              Update
            </Button>

            :
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
              disabled
            >
              Update
            </Button>

        }


      </div>

    </div>
  )
}

export default AddDdm