import {
    SUPER_ADMIN_SIGNIN_REQUEST,
    SUPER_ADMIN_SIGNIN_SUCCESS,
    SUPER_ADMIN_SIGNIN_FAILED,
    SUPER_ADMIN_LOGOUT,
    ADD_DDM_REQUEST,
    ADD_DDM_SUCCESS,
    ADD_DDM_FAILED,
    ADD_DDE_REQUEST,
    ADD_DDE_SUCCESS,
    ADD_DDE_FAILED,
    GET_ALL_DDE_REQUEST,
    GET_ALL_DDE_SUCCESS,
    GET_ALL_DDE_FAILED,
    DELETE_DDE_REQUEST,
    DELETE_DDE_SUCCESS,
    DELETE_DDE_FAILED,
    GET_DDM_ACTIVITY_REQUEST,
    GET_DDM_ACTIVITY_SUCCESS,
    GET_DDM_ACTIVITY_FAILED,
    GET_REGISTER_STUDENT_DETAILS_REQUEST,
    GET_REGISTER_STUDENT_DETAILS_SUCCESS,
    GET_REGISTER_STUDENT_DETAILS_FAILED,
    GET_ALL_CONVERTED_SCHOOL_REQUEST,
    GET_ALL_CONVERTED_SCHOOL_SUCCESS,
    GET_ALL_CONVERTED_SCHOOL_FAILED,
    GET_DISTRICT_FOR_CONVERTED_SCHOOL_REQUEST,
    GET_DISTRICT_FOR_CONVERTED_SCHOOL_SUCCESS,
    GET_DISTRICT_FOR_CONVERTED_SCHOOL_FAILED,
    ADD_VENUE_REQUEST,
    ADD_VENUE_SUCCESS,
    ADD_VENUE_FAILED,
    ADD_STUDENT_MARKS_REQUEST,
    ADD_STUDENT_MARKS_SUCCESS,
    ADD_STUDENT_MARKS_FAILED,
    GET_STUDENT_MARKS_REQUEST,
    GET_STUDENT_MARKS_SUCCESS,
    GET_STUDENT_MARKS_FAILED,
    SEND_STUDENT_RESULT_REQUEST,
    SEND_STUDENT_RESULT_SUCCESS,
    SEND_STUDENT_RESULT_FAILED,
  } from '../constants/superAdminConstants'  
  
  
      ///    SUPER ADMIN SIGNIN REDUCER    ///
      export const superAdminSigninReducer = (
          state = {
              loading: false,
              superAdminInfo: null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case SUPER_ADMIN_SIGNIN_REQUEST:
              return {
                 ...state, 
                 superAdminInfo: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case SUPER_ADMIN_SIGNIN_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                superAdminInfo: action.payload
                
              }
            case SUPER_ADMIN_SIGNIN_FAILED:
              return {
                  ...state,
                loading: false,
                superAdminInfo: null,
                isAuthenticated:false,
                error: action.payload
              }
              case SUPER_ADMIN_LOGOUT:
                return {
                  adminInfo: null
                }
            default:
              return state
          }
        }


      ///    Add or update ddm    ///
      export const updateDdmReducer = (
          state = {
              loading: false,
              data:null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case ADD_DDM_REQUEST:
              return {
                 ...state, 
                 data: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case ADD_DDM_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                data: action.payload
                
              }
            case ADD_DDM_FAILED:
              return {
                  ...state,
                loading: false,
                data: null,
                isAuthenticated:false,
                error: action.payload
              }
            default:
              return state
          }
        }


      ///    Add  dde    ///
      export const addNewDdeReducer = (
          state = {
              loading: false,
              data:null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case ADD_DDE_REQUEST:
              return {
                 ...state, 
                 data: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case ADD_DDE_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                data: action.payload
                
              }
            case ADD_DDE_FAILED:
              return {
                  ...state,
                loading: false,
                data: null,
                isAuthenticated:false,
                error: action.payload
              }
            default:
              return state
          }
        }

      ///    GET ALL DDE FOR A DISTRICT    ///
      export const getAllDDEReducer = (
          state = {
              loading: false,
              data:null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case GET_ALL_DDE_REQUEST:
              return {
                 ...state, 
                 data: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case GET_ALL_DDE_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                data: action.payload
                
              }
            case GET_ALL_DDE_FAILED:
              return {
                  ...state,
                loading: false,
                data: null,
                isAuthenticated:false,
                error: action.payload
              }
            default:
              return state
          }
        }


      ///    GET ALL DDE FOR A DISTRICT    ///
      export const deleteDDEReducer = (
          state = {
              loading: false,
              data:null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case DELETE_DDE_REQUEST:
              return {
                 ...state, 
                 data: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case DELETE_DDE_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                data: action.payload
                
              }
            case DELETE_DDE_FAILED:
              return {
                  ...state,
                loading: false,
                data: null,
                isAuthenticated:false,
                error: action.payload
              }
            default:
              return state
          }
        }


      ///    GET LOGIN LOGOUT ACTITVITY FOR DDM AND DDE   ///
      export const getDDMActivityReducer = (
          state = {
              loading: false,
              data:null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case GET_DDM_ACTIVITY_REQUEST:
              return {
                 ...state, 
                 data: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case GET_DDM_ACTIVITY_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                data: action.payload
                
              }
            case GET_DDM_ACTIVITY_FAILED:
              return {
                  ...state,
                loading: false,
                data: null,
                isAuthenticated:false,
                error: action.payload
              }
            default:
              return state
          }
        }


      ///    GET ALL REGISTERED STUENT DETAILS TO GENERATE REPORT   ///
      export const getRegisterStudentDetailsReducer = (
          state = {
              loading: false,
              data:null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case GET_REGISTER_STUDENT_DETAILS_REQUEST:
              return {
                 ...state, 
                 data: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case GET_REGISTER_STUDENT_DETAILS_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                data: action.payload
                
              }
            case GET_REGISTER_STUDENT_DETAILS_FAILED:
              return {
                  ...state,
                loading: false,
                data: null,
                isAuthenticated:false,
                error: action.payload
              }
            default:
              return state
          }
        }

        //SEND RESULT SMS TO STUDENT
        export const sendResultSmsReducer = (
          state = {
              sending: false,
              smsData:null,
              smsError: null
          }
          ,
          action
        ) => {
          switch (action.type) {
            case SEND_STUDENT_RESULT_REQUEST:
              return {
                  ...state,
                sending: true,  
              }
            case SEND_STUDENT_RESULT_SUCCESS:
              return {
                  ...state,
                sending: false,
                smsData: action.payload,
                smsError: null,
              }
            case SEND_STUDENT_RESULT_FAILED:
              return {
                  ...state,
                sending: false,
                smsData: null,
                smsError: action.payload,
              }
            default:
              return state
          }
        }
        




      // ADD AND UPDATE STUDENT MARKS
      export const addAndUpdateStudentMarksReducer = (
          state = {
              updating: false,
              updatedMarks:null,
              updateError: null,
          }
          ,
          action
        ) => {
          switch (action.type) {
            case ADD_STUDENT_MARKS_REQUEST:
              return {
                  ...state,
                updating: true,
                updatedMarks: null,
                updateError: null,
              }
            case ADD_STUDENT_MARKS_SUCCESS:
              return {
                  ...state,
                updating: false,
                updatedMarks: action.payload,
                updateError: null,
              }
            case ADD_STUDENT_MARKS_FAILED:
              return {
                  ...state,
                updating: false, 
                updatedMarks: null,
                updateError: action.payload,
              }
            default:
              return state
          }
        }


      // GET STUDENT MARKS
      export const getStudentMarksReducer = (
        state = {
            loadingMarks: false,
            marksData:null,
            error: null,
        }
        ,
        action
      ) => {
        switch (action.type) {
          case GET_STUDENT_MARKS_REQUEST:
            return {
                ...state,
              loadingMarks: true,
              marksData: null,
              error: null,
            }
          case GET_STUDENT_MARKS_SUCCESS:
            return {
                ...state,
              loadingMarks: false,
              marksData: action.payload,
              error: null,
            }
          case GET_STUDENT_MARKS_FAILED:
            return {
                ...state,
              loadingMarks: false, 
              marksData: action.payload,
              error: action.payload,
            }
          default:
            return state
        }
      }


      ///   GET ALL CONVERTED SCHOOL ///
      export const getAllConvertedSchoolReducer = (
          state = {
              loading: false,
              data:null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case GET_ALL_CONVERTED_SCHOOL_REQUEST:
              return {
                 ...state, 
                 data: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case GET_ALL_CONVERTED_SCHOOL_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                data: action.payload
                
              }
            case GET_ALL_CONVERTED_SCHOOL_FAILED:
              return {
                  ...state,
                loading: false,
                data: null,
                isAuthenticated:false,
                error: action.payload
              }
            default:
              return state
          }
        }


      ///   GET DISTRICT FOR CONVERTED SCHOOL ///
      export const getDistrictForConvertedSchoolReducer = (
          state = {
              loading: false,
              data:null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case GET_DISTRICT_FOR_CONVERTED_SCHOOL_REQUEST:
              return {
                 ...state, 
                 data: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case GET_DISTRICT_FOR_CONVERTED_SCHOOL_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                data: action.payload
                
              }
            case GET_DISTRICT_FOR_CONVERTED_SCHOOL_FAILED:
              return {
                  ...state,
                loading: false,
                data: null,
                isAuthenticated:false,
                error: action.payload
              }
            default:
              return state
          }
        }


      ///   ADD VENUE BY SUPERADMIN ///
      export const addVenueReducer = (
          state = {
              loading: false,
              data:null,
              error: null,
              isAuthenticated:false
          },
          action
        ) => {
          switch (action.type) {
            case ADD_VENUE_REQUEST:
              return {
                 ...state, 
                 data: null,
                 error: null,
                 isAuthenticated:false,
                 loading: true
              }
            case ADD_VENUE_SUCCESS:
              return {
                  ...state,
                loading: false,
                isAuthenticated: true,
                error: null,
                data: action.payload
                
              }
            case ADD_VENUE_FAILED:
              return {
                  ...state,
                loading: false,
                data: null,
                isAuthenticated:false,
                error: action.payload
              }
            default:
              return state
          }
        }


