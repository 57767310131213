import React from "react";
import { Typography, Button, FormLabel } from "@material-ui/core";
import useStyles from "./styles";
import { SuperAdminLogic } from "./SuperAdmin.logic";

import illustration from "../../images/login-illustration.jpg";

//define initial state for login form

const SuperAdmin = () => {
  const classes = useStyles();

  const { email, setEmail, password, setPassword, loginHander } =
    SuperAdminLogic();

  return (
    // Login Form
    <div className={classes.container}>
      <div className={classes.paper}>
        <div
          className={classes.form}
          autoComplete="off"
        >
          <Typography variant="h4" className={classes.text}>
            Login As Super Admin
          </Typography>

          <div className={classes.group}>
            <FormLabel className={classes.label}>Email:</FormLabel>
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              name="email"
              className={classes.box}
              required
              type="email"
            />
          </div>
          <div className={classes.group}>
            <FormLabel className={classes.label}>Password:</FormLabel>
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              name="password"
              className={classes.box}
              required
              type="password"
            />
          </div>

          <Button
            onClick={loginHander}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
        </div>
      </div>
      <div className={classes.paper1}>
        <img
          to="/"
          src={illustration}
          alt="icon"
          className={classes.image}
          width="100%"
        />
      </div>
    </div>
  );
};
export default SuperAdmin;
