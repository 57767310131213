export const subject_for_cbse_10th = [
    {
        "name": "English(1st Lang)"
    },
    {
        "name": "English(2nd Lang)"
    },
    {
        "name": "Bengali(1st Lang)"
    },
    {
        "name": "Bengali(2nd Lang)"
    },
    {
        "name": "Hindi"
    },
    {
        "name": "Physical Science and Education"
    },
    {
        "name": "Mathematics"
    },
    {
        "name": "Life Science"
    },
    {
        "name": "Geography"
    },
    {
        "name": "History"
    },
    {
        "name": "Sanskrit"
    },
    {
        "name": "Social Science"
    },
    {
        "name": "Science"
    },
    {
        "name": "Economics"
    },
    {
        "name": "Physics"
    },
    {
        "name": "Chemistry"
    },
    {
        "name": "Biology"
    },
    {
        "name": "Accounts"
    },
    {
        "name": "Costing taxation"
    },
    {
        "name": "Computer Applications"
    },
    {
        "name": "Economics"
    }
]


export const subject_for_cbse_12th = [
    {
        "name": "Physics"
    },
    {
        "name": "Chemistry"
    },
    {
        "name": "Biology"
    },
    {
        "name": "Mathematics"
    },
    {
        "name": "English"
    },
    {
        "name": "Bengali"
    },
    {
        "name": "Hindi"
    },
    {
        "name": "Economics"
    },
    {
        "name": "History"
    },
    {
        "name": "Geography"
    },
    {
        "name": "Sociology"
    },
    {
        "name": "Psychology"
    },
    {
        "name": "Commerce"
    },
    {
        "name": "Accountancy"
    },
    {
        "name": "Economics"
    },
    {
        "name": "Statistics"
    },
    {
        "name": "Business Studies"
    },
    {
        "name": "Political Science"
    },
    {
        "name": "Costing taxation"
    },
    {
        "name": "Computer Applications"
    },
]

export const subject_for_icse_10th = [
    {
        "name": "English(1st Lang)"
    },
    {
        "name": "English(2nd Lang)"
    },
    {
        "name": "Bengali(1st Lang)"
    },
    {
        "name": "Bengali(2nd Lang)"
    },
    {
        "name": "Hindi"
    },
    {
        "name": "Physical Science and Education"
    },
    {
        "name": "Mathematics"
    },
    {
        "name": "Life Science"
    },
    {
        "name": "Geography"
    },
    {
        "name": "History"
    },
    {
        "name": "Sanskrit"
    },
    {
        "name": "Social Science"
    },
    {
        "name": "Science"
    },
    {
        "name": "Economics"
    },
    {
        "name": "Physics"
    },
    {
        "name": "Chemistry"
    },
    {
        "name": "Biology"
    },
    {
        "name": "Accounts"
    },
    {
        "name": "Economics"
    },
    {
        "name": "Costing taxation"
    },
    {
        "name": "Computer Applications"
    },
]

export const subject_for_icse_12th = [
    {
        "name": "Physics"
    },
    {
        "name": "Chemistry"
    },
    {
        "name": "Biology"
    },
    {
        "name": "Mathematics"
    },
    {
        "name": "English"
    },
    {
        "name": "Bengali"
    },
    {
        "name": "Hindi"
    },
    {
        "name": "Economics"
    },
    {
        "name": "History"
    },
    {
        "name": "Geography"
    },
    {
        "name": "Sociology"
    },
    {
        "name": "Psychology"
    },
    {
        "name": "Commerce"
    },
    {
        "name": "Accountancy"
    },
    {
        "name": "Economics"
    },
    {
        "name": "Statistics"
    },
    {
        "name": "Business Studies"
    },
    {
        "name": "Political Science"
    },
    {
        "name": "Costing taxation"
    },
    {
        "name": "Computer Applications"
    },
]


export const subject_for_wbbse_10th = [
    {
        "name": "English(1st Lang)"
    },
    {
        "name": "English(2nd Lang)"
    },
    {
        "name": "Bengali(1st Lang)"
    },
    {
        "name": "Bengali(2nd Lang)"
    },
    {
        "name": "Hindi"
    },
    {
        "name": "Physical Science and Education"
    },
    {
        "name": "Mathematics"
    },
    {
        "name": "Life Science"
    },
    {
        "name": "Geography"
    },
    {
        "name": "History"
    },
    {
        "name": "Sanskrit"
    },
    {
        "name": "Social Science"
    },
    {
        "name": "Science"
    },
    {
        "name": "Economics"
    },
    {
        "name": "Physics"
    },
    {
        "name": "Chemistry"
    },
    {
        "name": "Biology"
    },
    {
        "name": "Accounts"
    },
    {
        "name": "Economics"
    },
    {
        "name": "Costing taxation"
    },
    {
        "name": "Computer Applications"
    },
]

export const subject_for_wbbse_12th = [
    {
        "name": "Physics"
    },
    {
        "name": "Chemistry"
    },
    {
        "name": "Biology"
    },
    {
        "name": "Mathematics"
    },
    {
        "name": "English"
    },
    {
        "name": "Bengali"
    },
    {
        "name": "Hindi"
    },
    {
        "name": "Economics"
    },
    {
        "name": "History"
    },
    {
        "name": "Geography"
    },
    {
        "name": "Sociology"
    },
    {
        "name": "Psychology"
    },
    {
        "name": "Commerce"
    },
    {
        "name": "Accountancy"
    },
    {
        "name": "Economics"
    },
    {
        "name": "Statistics"
    },
    {
        "name": "Business Studies"
    },
    {
        "name": "Political Science"
    },
    {
        "name": "Costing taxation"
    },
    {
        "name": "Law & Auditing"
    },
    {
        "name": "Computer Applications"
    },
    {
        "name": "Education"
    },
    {
        "name": "Sanskrit"
    },
]

