export const SUPER_ADMIN_SIGNIN_REQUEST = 'SUPER_ADMIN_SIGNIN_REQUEST'
export const SUPER_ADMIN_SIGNIN_SUCCESS = 'SUPER_ADMIN_SIGNIN_SUCCESS'
export const SUPER_ADMIN_SIGNIN_FAILED = 'SUPER_ADMIN_SIGNIN_FAILED'
export const SUPER_ADMIN_LOGOUT = 'SUPER_ADMIN_LOGOUT'


export const ADD_DDM_REQUEST = 'ADD_DDM_REQUEST'
export const ADD_DDM_SUCCESS = 'ADD_DDM_SUCCESS'
export const ADD_DDM_FAILED = 'ADD_DDM_FAILED'


export const ADD_DDE_REQUEST = 'ADD_DDE_REQUEST'
export const ADD_DDE_SUCCESS = 'ADD_DDE_SUCCESS'
export const ADD_DDE_FAILED = 'ADD_DDE_FAILED'


export const GET_ALL_DDE_REQUEST = 'GET_ALL_DDE_REQUEST'
export const GET_ALL_DDE_SUCCESS = 'GET_ALL_DDE_SUCCESS'
export const GET_ALL_DDE_FAILED = 'GET_ALL_DDE_FAILED'


export const DELETE_DDE_REQUEST = 'DELETE_DDE_REQUEST'
export const DELETE_DDE_SUCCESS = 'DELETE_DDE_SUCCESS'
export const DELETE_DDE_FAILED = 'DELETE_DDE_FAILED'


export const GET_DDM_ACTIVITY_REQUEST = 'GET_DDM_ACTIVITY_REQUEST'
export const GET_DDM_ACTIVITY_SUCCESS = 'GET_DDM_ACTIVITY_SUCCESS'
export const GET_DDM_ACTIVITY_FAILED = 'GET_DDM_ACTIVITY_FAILED'


export const GET_REGISTER_STUDENT_DETAILS_REQUEST = 'GET_REGISTER_STUDENT_DETAILS_REQUEST'
export const GET_REGISTER_STUDENT_DETAILS_SUCCESS = 'GET_REGISTER_STUDENT_DETAILS_SUCCESS'
export const GET_REGISTER_STUDENT_DETAILS_FAILED = 'GET_REGISTER_STUDENT_DETAILS_FAILED'

export const ADD_STUDENT_MARKS_REQUEST = 'ADD_STUDENT_MARKS_REQUEST'
export const ADD_STUDENT_MARKS_SUCCESS = 'ADD_STUDENT_MARKS_SUCCESS'
export const ADD_STUDENT_MARKS_FAILED = 'ADD_STUDENT_MARKS_FAILED'

export const GET_STUDENT_MARKS_REQUEST = 'GET_STUDENT_MARKS_REQUEST'
export const GET_STUDENT_MARKS_SUCCESS = 'GET_STUDENT_MARKS_SUCCESS'
export const GET_STUDENT_MARKS_FAILED = 'GET_STUDENT_MARKS_FAILED'

export const SEND_STUDENT_RESULT_REQUEST = 'SEND_STUDENT_RESULT_REQUEST'
export const SEND_STUDENT_RESULT_SUCCESS = 'SEND_STUDENT_RESULT_SUCCESS'
export const SEND_STUDENT_RESULT_FAILED = 'SEND_STUDENT_RESULT_FAILED'


export const GET_ALL_CONVERTED_SCHOOL_REQUEST = 'GET_ALL_CONVERTED_SCHOOL_REQUEST'
export const GET_ALL_CONVERTED_SCHOOL_SUCCESS = 'GET_ALL_CONVERTED_SCHOOL_SUCCESS'
export const GET_ALL_CONVERTED_SCHOOL_FAILED = 'GET_ALL_CONVERTED_SCHOOL_FAILED'


export const GET_DISTRICT_FOR_CONVERTED_SCHOOL_REQUEST = 'GET_DISTRICT_FOR_CONVERTED_SCHOOL_REQUEST'
export const GET_DISTRICT_FOR_CONVERTED_SCHOOL_SUCCESS = 'GET_DISTRICT_FOR_CONVERTED_SCHOOL_SUCCESS'
export const GET_DISTRICT_FOR_CONVERTED_SCHOOL_FAILED = 'GET_DISTRICT_FOR_CONVERTED_SCHOOL_FAILED'


export const ADD_VENUE_REQUEST = 'ADD_VENUE_REQUEST'
export const ADD_VENUE_SUCCESS = 'ADD_VENUE_SUCCESS'
export const ADD_VENUE_FAILED = 'ADD_VENUE_FAILED'